import {
  Routes,
  Route,
  useNavigationType,
  useLocation,
} from "react-router-dom";
import Home from "./pages/Home";
import Contact from "./pages/Contact";
import About from "./pages/About";
import Goods from "./pages/Goods";
import { useEffect } from "react";

function App() {
  const action = useNavigationType();
  const location = useLocation();
  const pathname = location.pathname;

  useEffect(() => {
    if (action !== "POP") {
      window.scrollTo(0, 0);
    }
  }, [action, pathname]);

  useEffect(() => {
    let title = "";
    let metaDescription = "";

    switch (pathname) {
      case "/":
        title = 'ООО "Сфера"';
        metaDescription = "Оптовая продажа товаров";
        break;
      case "/contact":
        title = 'Контакты - ООО "Сфера"';
        metaDescription = "Свяжитесь с нами, чтобы получить больше информации.";
        break;
      case "/about":
        title = 'О нас - ООО "Сфера"';
        metaDescription = "Информация о компании";
        break;
      case "/goods":
        title = 'Товары - ООО "Сфера"';
        metaDescription = "Наши товары";
        break;
    }

    if (title) {
      document.title = title;
    }

    if (metaDescription) {
      const metaDescriptionTag: HTMLMetaElement | null = document.querySelector(
        'head > meta[name="description"]'
      );
      if (metaDescriptionTag) {
        metaDescriptionTag.content = metaDescription;
      }
    }
  }, [pathname]);

  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/contact" element={<Contact />} />
      <Route path="/about" element={<About />} />
      <Route path="/goods" element={<Goods />} />
    </Routes>
  );
}
export default App;
