import { FunctionComponent, memo } from "react";
import { Link } from "react-router-dom";

const HeroContainer: FunctionComponent = memo(() => {
  return (
    <div className="self-stretch flex flex-row py-16 px-24 items-center justify-center gap-[141px] text-left text-17xl text-black font-body-s sm:flex-col">
      <div className="w-[534px] flex flex-col items-start justify-center gap-[36px]">
        <div className="w-[534px] flex flex-col items-start justify-start sm:items-center sm:justify-start">
          <h2 className="m-0 self-stretch relative text-[inherit] font-bold font-inherit sm:text-5xl sm:text-center">
            Ваш оптовый магазин
          </h2>
          <p className="m-0 relative text-xl inline-block w-[534px] sm:text-base sm:text-center sm:w-[370px]">
            “Сфера” – это успешная компания, специализирующаяся на оптовой
            продаже различных товаров, начиная с года ее основания в 2018.
            Компания предлагает широкий ассортимент продукции, включающий
            текстильные изделия, продукты питания, напитки, одежду и обувь,
            электрическую технику и многое другое.
          </p>
        </div>
        <Link
          className="cursor-pointer [text-decoration:none] bg-gold flex flex-row py-2 px-[22px] box-border items-start justify-center min-w-[150px] text-center text-xl text-[inherit] sm:hidden sm:items-center sm:justify-start"
          to="/goods"
        >
          <div className="flex-1 relative font-semibold">Товары</div>
        </Link>
      </div>
      <img
        className="relative w-[587.82px] h-[438px]"
        alt=""
        src="/marketplacerafiki.svg"
      />
    </div>
  );
});

export default HeroContainer;
