import { FunctionComponent, memo } from "react";
import { Input, Textarea } from "@chakra-ui/react";

const ContactFormContainer: FunctionComponent = memo(() => {
  return (
    <div className="self-stretch flex flex-row py-12 px-0 items-start justify-center gap-[48px] text-left text-17xl text-black font-body-s">
      <img
        className="flex-1 relative rounded-lg max-w-full overflow-hidden h-[346px] object-cover"
        alt=""
        src="/formimage@2x.png"
      />
      <form
        className="flex-1 flex flex-col items-start justify-center gap-[10px]"
        action="https://formsubmit.co/sphere12@rambler.ru"
        method="post"
      >
        <div className="relative text-17xl font-semibold font-body-s text-black text-left">
          Свяжитесь с нами
        </div>
        <label className="cursor-pointer relative text-sm font-body-s text-black text-left">
          Ваше имя
        </label>
        <Input
          className="bg-[transparent]"
          variant="outline"
          width="222px"
          placeholder="Введите ваше имя"
          w="222px"
        />
        <label className="cursor-pointer relative text-sm font-body-s text-black text-left">
          Ваша почта
        </label>
        <Input
          className="bg-[transparent]"
          variant="outline"
          width="222px"
          placeholder="Введите вашу электронную почту"
          w="222px"
        />
        <label className="cursor-pointer relative text-sm font-body-s text-black text-left">
          Ваше сообщение
        </label>
        <Textarea
          className="relative"
          variant="outline"
          w="320px"
          placeholder="Сообщение"
        />
        <button className="cursor-pointer [border:none] py-2 px-[22px] bg-gold flex flex-col box-border items-center justify-start min-w-[150px]">
          <div className="relative text-xl font-medium font-body-s text-black text-center">
            Отправить
          </div>
        </button>
      </form>
      <div className="self-stretch relative box-border w-px border-r-[1px] border-solid border-peru" />
      <div className="self-stretch flex flex-col items-start justify-start gap-[12px]">
        <div className="self-stretch relative font-semibold">
          Наши реквизиты
        </div>
        <div className="flex flex-col items-start justify-start text-base">
          <b className="relative">Полное наименование:</b>
          <div className="relative">
            <p className="m-0">
              ОБЩЕСТВО С ОГРАНИЧЕННОЙ ОТВЕТСТВЕННОСТЬЮ «Сфера»
            </p>
            <p className="m-0">
              <b className="font-body-s">ИНН/КПП:</b>
              <span> 7724449361 / 772601001</span>
            </p>
            <p className="m-0">
              <b className="font-body-s">{`ОГРН: `}</b>
              <span>1187746736495</span>
            </p>
            <p className="m-0">
              <b className="font-body-s">БИК:</b>
              <span> 044030786</span>
            </p>
            <p className="m-0">
              <b className="font-body-s">К/С:</b>
              <span> 30101810600000000786</span>
            </p>
          </div>
          <div className="relative">
            <b>Р/С:</b>
            <span> 40702810132410011835</span>
          </div>
          <div className="relative">
            в филиал "САНКТ-ПЕТЕРБУРГСКИЙ" АО "АЛЬФА-БАНК"
          </div>
        </div>
      </div>
    </div>
  );
});

export default ContactFormContainer;
