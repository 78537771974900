import { FunctionComponent, useCallback } from "react";
import HComponent from "../components/HComponent";
import FComponent from "../components/FComponent";

const About: FunctionComponent = () => {
  const onLinkClick = useCallback(() => {
    window.open("/");
  }, []);

  const onLink1Click = useCallback(() => {
    window.open("goods");
  }, []);

  const onLink2Click = useCallback(() => {
    window.open("about");
  }, []);

  const onLink3Click = useCallback(() => {
    window.open("contact");
  }, []);

  return (
    <div className="relative bg-white w-full overflow-hidden flex flex-col items-center justify-start text-left text-base text-black font-body-s">
      <HComponent
        logo="/logo-12@2x.png"
        headerNavbarJustifyContent="flex-start"
        onLinkClick={onLinkClick}
        onLink1Click={onLink1Click}
        onLink2Click={onLink2Click}
        onLink3Click={onLink3Click}
      />
      <div className="self-stretch flex flex-col py-16 px-24 items-center justify-start gap-[10px]">
        <img
          className="self-stretch relative max-w-full overflow-hidden h-[564px] shrink-0 object-cover"
          alt=""
          src="/rectangle-1@2x.png"
        />
        <div className="self-stretch flex flex-row py-6 px-0 items-end justify-start gap-[48px]">
          <div className="flex flex-col items-start justify-start gap-[4px]">
            <b className="relative leading-[175%]">Позвоните нам</b>
            <div className="self-stretch relative box-border h-px border-t-[1px] border-solid border-gainsboro" />
            <div className="relative leading-[175%] font-light">
              Телефон: +7 (904) 768 19-55
            </div>
            <div className="self-stretch relative box-border h-px border-t-[1px] border-solid border-gainsboro" />
            <div className="relative leading-[175%] font-light">
              Почта: sphere12@rambler.ru
            </div>
          </div>
          <div className="flex-1 relative leading-[175%]">
            <p className="m-0">
              ООО “Сфера” – это успешная компания, специализирующаяся на оптовой
              продаже различных товаров, начиная с года ее основания в 2018.
              Компания предлагает широкий ассортимент продукции, включающий
              текстильные изделия, продукты питания, напитки, одежду и обувь,
              электрическую технику и многое другое.
            </p>
            <p className="m-0">&nbsp;</p>
            <p className="m-0">
              Продукты, которые предлагает “Сфера”, отличаются высоким качеством
              и доступны по конкурентоспособным оптовым ценам. Компания имеет
              прочные партнерские отношения с ведущими производителями и
              поставщиками, что позволяет обеспечивать постоянное наличие
              широкого ассортимента товаров.
            </p>
          </div>
          <div className="flex-1 relative leading-[175%]">
            <p className="m-0">
              Одним из ключевых преимуществ компании является ее оперативность и
              надежность. “Сфера” гордится быстрой обработкой заказов и
              своевременной доставкой товаров клиентам. Благодаря слаженной
              работе логистического отдела и надежным партнерским перевозчикам,
              компания осуществляет доставку товаров по всей стране.
            </p>
            <p className="m-0">&nbsp;</p>
            <p className="m-0">
              Компания также уделяет большое внимание обслуживанию клиентов.
              Профессиональная команда сотрудников “Сфера” всегда готова помочь
              клиентам в выборе продукции и предоставить консультации. Клиенты
              могут рассчитывать на индивидуальный подход, гибкую систему скидок
              и гарантию качества предоставляемых товаров.
            </p>
          </div>
        </div>
      </div>
      <FComponent
        vk="/uilvk1.svg"
        youtube="/uilyoutube1.svg"
        logo="/logo-13@2x.png"
      />
    </div>
  );
};

export default About;
