import { FunctionComponent, memo } from "react";
import { Link } from "react-router-dom";

type FComponentType = {
  vk?: string;
  youtube?: string;
  logo?: string;
};

const FComponent: FunctionComponent<FComponentType> = memo(
  ({ vk, youtube, logo }) => {
    return (
      <div className="self-stretch bg-white overflow-hidden flex flex-col py-4 px-6 items-center justify-center gap-[24px] text-left text-3xl text-black font-body-s">
        <div className="flex flex-row items-center justify-center gap-[16px]">
          <Link
            className="cursor-pointer [text-decoration:none] relative font-light text-[inherit]"
            to="/"
          >
            Главная
          </Link>
          <Link
            className="cursor-pointer [text-decoration:none] relative font-light text-[inherit]"
            to="/about"
          >
            О нас
          </Link>
          <Link
            className="cursor-pointer [text-decoration:none] relative font-light text-[inherit]"
            to="/goods"
          >
            Товары
          </Link>
          <Link
            className="cursor-pointer [text-decoration:none] relative font-light text-[inherit]"
            to="/contact"
          >
            Контакты
          </Link>
          <div className="relative box-border w-px h-[51px] border-r-[1px] border-solid border-black" />
          <img className="relative w-6 h-6" alt="" src={vk} />
          <img className="relative w-6 h-6" alt="" src={youtube} />
        </div>
        <img
          className="relative w-[79px] h-[86px] object-cover"
          alt=""
          src={logo}
        />
        <div className="flex flex-row items-center justify-center gap-[16px]">
          <div className="relative font-light">Политика конфиденциальности</div>
          <div className="relative font-light">Условия использования</div>
        </div>
      </div>
    );
  }
);

export default FComponent;
