import { FunctionComponent, memo } from "react";
import { Link } from "react-router-dom";

const GoodsContainer: FunctionComponent = memo(() => {
  return (
    <div className="self-stretch bg-gold flex flex-row py-16 px-0 items-center justify-center text-left text-17xl text-black font-body-s sm:flex-col">
      <img
        className="relative w-[367px] h-[383px] object-cover"
        alt=""
        src="/goodslogo@2x.png"
      />
      <div className="flex flex-col items-center justify-center gap-[24px]">
        <div className="flex flex-col py-0 px-12 items-start justify-start gap-[8px] sm:items-center sm:justify-start">
          <h2 className="m-0 relative text-[inherit] font-bold font-inherit sm:text-5xl sm:text-left">
            Подушки, простыни, одеяла
          </h2>
          <p className="m-0 relative text-xl leading-[120%] inline-block w-[498px] sm:text-base sm:text-center sm:w-[370px]">
            Мы предлагаем широкий выбор подушек различных размеров, форм и
            наполнителей. Независимо от ваших предпочтений - мягкие, упругие,
            ортопедические или антиаллергенные, у нас есть подушка, которая
            подойдет именно вам. Наши подушки обеспечивают оптимальную поддержку
            шеи и головы, способствуя здоровому сну и расслаблению.
          </p>
        </div>
        <Link
          className="cursor-pointer [text-decoration:none] bg-white flex flex-col py-2 px-[22px] box-border items-center justify-start min-w-[150px] text-center text-xl text-[inherit]"
          to="/goods"
        >
          <div className="relative font-medium">Товары</div>
        </Link>
      </div>
    </div>
  );
});

export default GoodsContainer;
