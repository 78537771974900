import { FunctionComponent, useCallback } from "react";
import HComponent from "../components/HComponent";
import HeroContainer from "../components/HeroContainer";
import FeaturesContainer from "../components/FeaturesContainer";
import GoodsContainer from "../components/GoodsContainer";
import AccordionContainer from "../components/AccordionContainer";
import FComponent from "../components/FComponent";

const Home: FunctionComponent = () => {
  const onLinkClick = useCallback(() => {
    window.open("/");
  }, []);

  const onLink1Click = useCallback(() => {
    window.open("goods");
  }, []);

  const onLink2Click = useCallback(() => {
    window.open("about");
  }, []);

  const onLink3Click = useCallback(() => {
    window.open("contact");
  }, []);

  return (
    <div className="relative bg-white w-full overflow-hidden flex flex-col items-center justify-start text-left text-5xl text-blue-gray-400 font-body-s">
      <HComponent
        logo="/logo-1@2x.png"
        headerNavbarJustifyContent="center"
        onLinkClick={onLinkClick}
        onLink1Click={onLink1Click}
        onLink2Click={onLink2Click}
        onLink3Click={onLink3Click}
      />
      <HeroContainer />
      <FeaturesContainer />
      <div className="self-stretch bg-blue-gray-100 flex flex-row py-12 px-20 items-center justify-center gap-[80px] sm:flex-col">
        <div className="flex flex-row items-center justify-center gap-[8px]">
          <img
            className="relative w-[33.16px] h-8"
            alt=""
            src="/group-45.svg"
          />
          <b className="relative leading-[110%]">SmartFinder</b>
        </div>
        <div className="flex flex-row items-center justify-center gap-[8px]">
          <img className="relative w-8 h-8" alt="" src="/vector.svg" />
          <b className="relative leading-[110%]">Zoomerr</b>
        </div>
        <div className="flex flex-row items-center justify-center gap-[8px]">
          <img className="relative w-[30.4px] h-8" alt="" src="/vector1.svg" />
          <b className="relative leading-[110%]">SHELLS</b>
        </div>
        <div className="flex flex-row items-center justify-center gap-[8px]">
          <img className="relative w-[21.03px] h-8" alt="" src="/vector2.svg" />
          <b className="relative leading-[110%]">WAVES</b>
        </div>
        <div className="flex flex-row items-center justify-center gap-[8px]">
          <img
            className="relative w-[55.81px] h-8"
            alt=""
            src="/group-46.svg"
          />
          <b className="relative leading-[110%]">ArtVenue</b>
        </div>
      </div>
      <GoodsContainer />
      <AccordionContainer />
      <FComponent
        vk="/uilvk.svg"
        youtube="/uilyoutube.svg"
        logo="/logo-11@2x.png"
      />
    </div>
  );
};

export default Home;
