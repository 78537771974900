import { FunctionComponent, memo } from "react";
import Card from "./Card";

const FeaturesContainer: FunctionComponent = memo(() => {
  return (
    <div className="self-stretch flex flex-row pt-0 px-0 pb-12 items-center justify-center text-left text-xl text-black font-body-s sm:flex-col">
      <Card
        icon="/solarboxbold.svg"
        cardHeading="Широкий ассортимент"
        cardText="Компания “Сфера” имеет широкий ассортимент товаров, включая текстиль, продукты питания, напитки, одежду, обувь, электротехнику и многое другое. Это позволяет клиентам экономить время и упрощать процесс закупки, так как они могут найти все, что им нужно, в одном месте."
        cardBodyAlignItems="center"
        cardHeadingAlignSelf="stretch"
      />
      <Card
        icon="/solarbagheartbold.svg"
        cardHeading="Конкурентоспособные цены"
        cardText="Компания “Сфера” предлагает оптовые цены на свою продукцию. Благодаря партнерам-производителям и поставщикам, она может предоставлять привлекательные цены своим клиентам, что привлекает больше клиентов и удерживает их благодаря выгодным предложениям."
        cardBodyAlignItems="flex-start"
        cardHeadingAlignSelf="unset"
      />
      <Card
        icon="/solarchecksquarebold.svg"
        cardHeading="Оперативность и надежность"
        cardText="Компания “Сфера” выделяется своей оперативностью и надежностью в обработке заказов и доставке товаров. Клиенты могут рассчитывать на быструю обработку заказов и своевременную доставку, что гарантирует удовлетворение их потребностей."
      />
    </div>
  );
});

export default FeaturesContainer;
