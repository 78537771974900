import { FunctionComponent, memo, useMemo } from "react";
import { Link } from "react-router-dom";
import CSS, { Property } from "csstype";

type HComponentType = {
  logo?: string;

  /** Style props */
  headerNavbarHeight?: Property.Height;
  headerNavbarJustifyContent?: Property.JustifyContent;

  /** Action props */
  onLinkClick?: () => void;
  onLink1Click?: () => void;
  onLink2Click?: () => void;
  onLink3Click?: () => void;
};

const HComponent: FunctionComponent<HComponentType> = memo(
  ({
    logo,
    headerNavbarHeight,
    headerNavbarJustifyContent,
    onLinkClick,
    onLink1Click,
    onLink2Click,
    onLink3Click,
  }) => {
    const containerheaderStyle: CSS.Properties = useMemo(() => {
      return {
        height: headerNavbarHeight,
        justifyContent: headerNavbarJustifyContent,
      };
    }, [headerNavbarHeight, headerNavbarJustifyContent]);

    return (
      <div
        className="self-stretch bg-white flex flex-row py-8 px-16 items-center justify-center text-left text-base text-dimgray font-body-s"
        style={containerheaderStyle}
      >
        <div className="flex-1 flex flex-row items-center justify-between">
          <Link
            className="cursor-pointer [text-decoration:none] relative text-[inherit]"
            to="/"
          >
            Главная
          </Link>
          <Link
            className="cursor-pointer [text-decoration:none] relative text-[inherit]"
            to="/goods"
          >
            Товары
          </Link>
          <img
            className="relative w-[79px] h-[86px] object-cover"
            alt=""
            src={logo}
          />
          <Link
            className="cursor-pointer [text-decoration:none] relative text-[inherit]"
            to="/about"
          >
            О нас
          </Link>
          <Link
            className="cursor-pointer [text-decoration:none] relative text-[inherit]"
            to="/contact"
          >
            Контакты
          </Link>
        </div>
      </div>
    );
  }
);

export default HComponent;
