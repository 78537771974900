import { FunctionComponent, memo } from "react";
import {
  AccordionItem,
  AccordionButton,
  AccordionIcon,
  AccordionPanel,
  Accordion,
  Box,
} from "@chakra-ui/react";

const AccordionContainer: FunctionComponent = memo(() => {
  return (
    <div className="self-stretch bg-white flex flex-col py-12 px-20 items-center justify-center gap-[48px] text-left text-17xl text-black font-body-s">
      <b className="relative leading-[24px] sm:text-center">
        Отвечаем на ваши вопросы
      </b>
      <div className="self-stretch flex flex-col items-center justify-center gap-[16px]">
        <Accordion className="self-stretch" allowToggle>
          <AccordionItem>
            <AccordionButton>
              <Box flex="1" textAlign="left">
                Как долго существует компания "Сфера"?
              </Box>
              <AccordionIcon />
            </AccordionButton>
            <AccordionPanel pb={4}>
              Компания "Сфера" существует с 2018 года. У нас есть опыт и
              надежность, чтобы обеспечить высокое качество продукции и
              качественное обслуживание.
            </AccordionPanel>
          </AccordionItem>
        </Accordion>
        <Accordion className="self-stretch" allowToggle>
          <AccordionItem>
            <AccordionButton>
              <Box flex="1" textAlign="left">
                Какие товары можно приобрести в компании "Сфера"?
              </Box>
              <AccordionIcon />
            </AccordionButton>
            <AccordionPanel pb={4}>
              Мы предлагаем широкий ассортимент товаров, включая текстильные
              изделия, продукты питания, напитки, одежду и обувь, электрическую
              технику и многое другое. Мы стремимся удовлетворить разнообразные
              потребности наших клиентов.
            </AccordionPanel>
          </AccordionItem>
        </Accordion>
        <Accordion className="self-stretch" allowToggle>
          <AccordionItem>
            <AccordionButton>
              <Box flex="1" textAlign="left">
                Какие гарантии на качество товаров предоставляет "Сфера"?
              </Box>
              <AccordionIcon />
            </AccordionButton>
            <AccordionPanel pb={4}>
              Мы гарантируем высокое качество всех наших товаров. Мы работаем
              только с надежными производителями и поставщиками, чтобы
              обеспечить качество и надежность продукции. В случае возникновения
              проблем с качеством, мы предоставляем возможность возврата или
              замены товара.
            </AccordionPanel>
          </AccordionItem>
        </Accordion>
        <Accordion className="self-stretch" allowToggle>
          <AccordionItem>
            <AccordionButton>
              <Box flex="1" textAlign="left">
                Каковы условия оптовой закупки у "Сфера"?
              </Box>
              <AccordionIcon />
            </AccordionButton>
            <AccordionPanel pb={4}>
              Мы предлагаем гибкие условия оптовой закупки для наших клиентов.
              Минимальный заказ и условия оптовых скидок могут быть уточнены у
              нашей команды продаж. Мы стремимся установить долгосрочные
              партнерские отношения с нашими клиентами и обеспечить
              взаимовыгодные условия сотрудничества.
            </AccordionPanel>
          </AccordionItem>
        </Accordion>
      </div>
    </div>
  );
});

export default AccordionContainer;
