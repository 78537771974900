import { FunctionComponent, useCallback } from "react";
import HComponent from "../components/HComponent";
import ContactFormContainer from "../components/ContactFormContainer";
import FComponent from "../components/FComponent";

const Contact: FunctionComponent = () => {
  const onLinkClick = useCallback(() => {
    window.open("/");
  }, []);

  const onLink1Click = useCallback(() => {
    window.open("goods");
  }, []);

  const onLink2Click = useCallback(() => {
    window.open("about");
  }, []);

  const onLink3Click = useCallback(() => {
    window.open("contact");
  }, []);

  return (
    <div className="relative bg-white w-full overflow-hidden flex flex-col items-center justify-start text-center text-base text-pink font-body-s">
      <HComponent
        logo="/logo-12@2x.png"
        headerNavbarHeight="110px"
        onLinkClick={onLinkClick}
        onLink1Click={onLink1Click}
        onLink2Click={onLink2Click}
        onLink3Click={onLink3Click}
      />
      <div className="self-stretch flex flex-col py-16 px-24 items-center justify-start gap-[10px]">
        <div className="self-stretch flex flex-col items-center justify-center gap-[4px]">
          <div className="relative tracking-[0.05em] uppercase inline-block w-[834px]">
            Контакты
          </div>
          <b className="relative text-[48px] inline-block text-gold w-[834px]">
            Наш офис
          </b>
        </div>
        <iframe
          className="[border:none] self-stretch relative h-[430px] overflow-hidden shrink-0"
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2255.8468830358383!2d37.59369507719021!3d55.56986460606642!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x414aadea44e5726b%3A0xeb1df078f06985c8!2z0JLQsNGA0YjQsNCy0YHQutC-0LUg0YguLCAxMTggMSwg0JzQvtGB0LrQstCwLCDQoNC-0YHRgdC40Y8sIDExNzQxOA!5e0!3m2!1sru!2sde!4v1688740201295!5m2!1sru!2sde"
        />
        <div className="self-stretch flex flex-row py-6 px-0 items-center justify-center gap-[48px] text-left text-black">
          <div className="flex flex-row items-center justify-start gap-[12px]">
            <img
              className="relative rounded-lg w-12 h-12 overflow-hidden shrink-0"
              alt=""
              src="/solarmappointbold.svg"
            />
            <div className="relative leading-[175%]">
              117105, город Москва, Варшавское ш, д. 1а, помещ. 3к/1п
            </div>
          </div>
          <div className="flex flex-row items-center justify-center gap-[12px]">
            <img
              className="relative rounded-lg w-12 h-12"
              alt=""
              src="/solarphoneroundedbold.svg"
            />
            <div className="relative leading-[175%] inline-block w-[324px] shrink-0">
              +7 (904) 768 19-55
            </div>
          </div>
          <div className="flex flex-row items-center justify-center gap-[12px]">
            <img
              className="relative rounded-lg w-12 h-12"
              alt=""
              src="/solarletterbold.svg"
            />
            <div className="relative leading-[175%]">sphere12@rambler.ru</div>
          </div>
        </div>
        <ContactFormContainer />
      </div>
      <FComponent
        vk="/uilvk1.svg"
        youtube="/uilyoutube1.svg"
        logo="/logo-13@2x.png"
      />
    </div>
  );
};

export default Contact;
