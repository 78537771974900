import { FunctionComponent, memo, useMemo } from "react";
import CSS, { Property } from "csstype";

type CardType = {
  icon?: string;
  cardHeading?: string;
  cardText?: string;

  /** Style props */
  cardBodyAlignItems?: Property.AlignItems;
  cardHeadingAlignSelf?: Property.AlignSelf;
};

const Card: FunctionComponent<CardType> = memo(
  ({
    icon,
    cardHeading,
    cardText,
    cardBodyAlignItems,
    cardHeadingAlignSelf,
  }) => {
    const cardBodyStyle: CSS.Properties = useMemo(() => {
      return {
        alignItems: cardBodyAlignItems,
      };
    }, [cardBodyAlignItems]);

    const cardHeadingStyle: CSS.Properties = useMemo(() => {
      return {
        alignSelf: cardHeadingAlignSelf,
      };
    }, [cardHeadingAlignSelf]);

    return (
      <div className="bg-white flex flex-col py-[39px] px-9 items-start justify-start gap-[28px] text-left text-xl text-black font-body-s">
        <img className="relative w-16 h-16" alt="" src={icon} />
        <div
          className="flex flex-col items-start justify-start gap-[4px]"
          style={cardBodyStyle}
        >
          <b className="relative leading-[100%]" style={cardHeadingStyle}>
            {cardHeading}
          </b>
          <div className="relative text-base leading-[115%] inline-block w-[308px]">
            {cardText}
          </div>
        </div>
      </div>
    );
  }
);

export default Card;
