import { FunctionComponent, useCallback } from "react";
import { Link } from "react-router-dom";
import HComponent from "../components/HComponent";
import FComponent from "../components/FComponent";

const Goods: FunctionComponent = () => {
  const onLinkClick = useCallback(() => {
    window.open("/");
  }, []);

  const onLink1Click = useCallback(() => {
    window.open("goods");
  }, []);

  const onLink2Click = useCallback(() => {
    window.open("about");
  }, []);

  const onLink3Click = useCallback(() => {
    window.open("contact");
  }, []);

  return (
    <div className="relative bg-white w-full overflow-hidden flex flex-col items-center justify-start text-left text-lg text-blue-gray-900 font-body-s">
      <HComponent
        logo="/logo-12@2x.png"
        onLinkClick={onLinkClick}
        onLink1Click={onLink1Click}
        onLink2Click={onLink2Click}
        onLink3Click={onLink3Click}
      />
      <div className="self-stretch bg-white h-[1310px] flex flex-col py-16 px-24 box-border items-center justify-start gap-[24px]">
        <div className="self-stretch flex flex-row items-start justify-between text-xl">
          <div className="flex flex-col items-center justify-start gap-[24px]">
            <div className="w-[708px] flex flex-col items-start justify-start gap-[8px]">
              <b className="self-stretch relative tracking-[3px] leading-[100%] uppercase hidden text-blue-gray-600">
                Caption
              </b>
              <div className="self-stretch relative text-53xl leading-[110%] font-extrabold hidden">
                Building apps just got easier
              </div>
              <b className="self-stretch relative text-21xl leading-[110%]">
                Наши товары
              </b>
            </div>
            <div className="self-stretch relative text-lg leading-[160%] hidden">
              Ac urna elementum purus vulputate tincidunt. Quam maecenas feugiat
              congue orci, eget tellus pellentesque aliquet. Felis fringilla
              morbi dui ac consequat risus pharetra odio pulvinar. Id
              pellentesque in tortor nec nulla cras sapien. Adipiscing nisi
              tellus quisque aliquet sit risus elementum scelerisque.
            </div>
            <div className="hidden flex-row items-center justify-start gap-[16px] text-base text-blue-600">
              <div className="rounded-lg bg-blue-600 flex flex-row py-5 px-6 items-center justify-center text-5xl text-white border-[2px] border-solid border-blue-600">
                <img
                  className="relative w-6 h-6 overflow-hidden shrink-0 hidden"
                  alt=""
                  src="/icon--jamicons--outline--logos--plus.svg"
                />
                <div className="flex flex-row py-0 px-6 items-center justify-center">
                  <b className="relative tracking-[0.5px] leading-[24px]">
                    Get Started
                  </b>
                </div>
                <img
                  className="relative w-6 h-6 overflow-hidden shrink-0 hidden"
                  alt=""
                  src="/icon--jamicons--outline--logos--arrowright.svg"
                />
              </div>
              <div className="rounded-lg hidden flex-row p-3 items-center justify-center border-[2px] border-solid border-blue-600">
                <img
                  className="relative w-6 h-6 overflow-hidden shrink-0 hidden"
                  alt=""
                  src="/icon--jamicons--outline--logos--plus1.svg"
                />
                <div className="flex flex-row py-0 px-4 items-center justify-center">
                  <div className="relative tracking-[0.5px] leading-[24px] font-medium">
                    Button Text
                  </div>
                </div>
                <img
                  className="relative w-6 h-6 overflow-hidden shrink-0"
                  alt=""
                  src="/icon--jamicons--outline--logos--arrowright1.svg"
                />
              </div>
              <div className="rounded-lg hidden flex-row py-3 px-2 items-center justify-center relative">
                <img
                  className="relative w-6 h-6 overflow-hidden shrink-0 hidden z-[0]"
                  alt=""
                  src="/icon--jamicons--outline--logos--plus1.svg"
                />
                <div className="flex flex-row py-0 px-4 items-center justify-center z-[1]">
                  <div className="relative tracking-[0.5px] leading-[24px] font-medium">
                    Button Text
                  </div>
                </div>
                <img
                  className="relative w-6 h-6 overflow-hidden shrink-0 z-[2]"
                  alt=""
                  src="/icon--jamicons--outline--logos--arrowright1.svg"
                />
                <div className="my-0 mx-[!important] absolute top-[8px] left-[24px] rounded-xl bg-default-alert hidden flex-row py-[0.5px] px-[5.5px] items-center justify-center z-[3] text-center text-xs text-white">
                  <div className="relative leading-[140%]">9</div>
                </div>
              </div>
            </div>
          </div>
          <Link
            className="cursor-pointer [text-decoration:none] rounded-lg flex flex-row py-3 px-0 items-center justify-center relative text-base text-orange"
            to="/contact"
          >
            <img
              className="relative w-6 h-6 overflow-hidden shrink-0 hidden z-[0]"
              alt=""
              src="/icon--jamicons--outline--logos--plus2.svg"
            />
            <div className="flex flex-row py-0 px-4 items-center justify-center z-[1]">
              <div className="relative tracking-[0.5px] leading-[24px] font-medium">
                Получить наш каталог
              </div>
            </div>
            <img
              className="relative w-6 h-6 overflow-hidden shrink-0 z-[2]"
              alt=""
              src="/icon--jamicons--outline--logos--arrowright2.svg"
            />
            <div className="my-0 mx-[!important] absolute top-[8px] left-[24px] rounded-xl bg-default-alert hidden flex-row py-[0.5px] px-[5.5px] items-center justify-center z-[3] text-center text-xs text-white">
              <div className="relative leading-[140%]">9</div>
            </div>
          </Link>
        </div>
        <div className="self-stretch flex flex-row items-center justify-center gap-[24px] sm:flex-col">
          <div className="flex-1 rounded-8xs h-[354px] overflow-hidden flex flex-col items-start justify-start relative bg-[url(/public/content-box@3x.png)] bg-cover bg-no-repeat bg-[top] sm:flex-[unset] sm:self-stretch">
            <img
              className="self-stretch max-w-full overflow-hidden max-h-full object-cover hidden z-[0]"
              alt=""
              src="/picture@2x.png"
            />
            <div className="self-stretch [background:linear-gradient(180deg,_rgba(15,_23,_42,_0.5),_rgba(15,_23,_42,_0))] flex flex-col pt-6 px-6 pb-16 items-start justify-start gap-[16px] z-[1]">
              <div className="hidden flex-row items-center justify-center">
                <img
                  className="relative w-16 h-16 overflow-hidden shrink-0 hidden"
                  alt=""
                  src="/icon--iconoir--threestars.svg"
                />
              </div>
              <div className="self-stretch flex flex-col items-start justify-start gap-[4px] text-base text-white">
                <div className="self-stretch relative leading-[110%] font-medium hidden">
                  Текстиль
                </div>
                <div className="self-stretch flex flex-row items-center justify-start gap-[8px] text-5xl">
                  <b className="flex-1 relative leading-[110%]">
                    Текстиль для дома
                  </b>
                  <div className="rounded bg-green-100 h-6 hidden flex-row py-2 px-1 box-border items-center justify-center text-sm text-green-800">
                    <img
                      className="relative w-4 h-4 overflow-hidden shrink-0 hidden"
                      alt=""
                      src="/icon--jamicons--outline--logos--circle.svg"
                    />
                    <div className="flex flex-row py-0 px-1 items-center justify-center">
                      <div className="relative leading-[140%]">+2,5%</div>
                    </div>
                    <img
                      className="relative w-4 h-4 overflow-hidden shrink-0"
                      alt=""
                      src="/icon--iconoir--statup.svg"
                    />
                  </div>
                  <img
                    className="relative w-6 h-6 overflow-hidden shrink-0 hidden"
                    alt=""
                    src="/icon--jamicons--outline--logos--pencil.svg"
                  />
                </div>
              </div>
              <img
                className="self-stretch max-w-full overflow-hidden h-[220px] shrink-0 object-cover hidden"
                alt=""
                src="/picture1@2x.png"
              />
              <div className="self-stretch relative leading-[160%] hidden">
                Vehicula sit sit pharetra bibendum ut risus accumsan. Purus, in
                metus, enim, ipsum morbi euismod pellentesque. Mattis pharetra
                accumsan eget est mi enim, id. Sit quam tortor eu tellus non, in
                euismod integer.
              </div>
              <div className="self-stretch hidden flex-row pt-4 px-0 pb-0 items-center justify-start gap-[16px]">
                <img
                  className="rounded-81xl w-16 h-16 object-cover"
                  alt=""
                  src="/user-thumb@2x.png"
                />
                <div className="flex-1 flex flex-col items-start justify-start">
                  <div className="self-stretch relative leading-[160%]">
                    Ralph Edwards
                  </div>
                  <div className="self-stretch relative text-base leading-[140%] text-blue-gray-600">{`Harvesting 32KWh `}</div>
                </div>
              </div>
            </div>
            <div className="self-stretch hidden flex-row p-4 items-start justify-start gap-[16px] z-[2] text-base text-white">
              <div className="flex-1 rounded-lg bg-blue-600 flex flex-row p-3 items-center justify-center border-[2px] border-solid border-blue-600">
                <img
                  className="relative w-6 h-6 overflow-hidden shrink-0 hidden"
                  alt=""
                  src="/icon--jamicons--outline--logos--plus3.svg"
                />
                <div className="flex flex-row py-0 px-4 items-center justify-center">
                  <div className="relative tracking-[0.5px] leading-[24px] font-medium">
                    Button Text
                  </div>
                </div>
                <img
                  className="relative w-6 h-6 overflow-hidden shrink-0"
                  alt=""
                  src="/icon--jamicons--outline--logos--arrowright3.svg"
                />
              </div>
              <div className="flex-1 rounded-lg flex flex-row p-3 items-center justify-center text-blue-600 border-[2px] border-solid border-blue-600">
                <img
                  className="relative w-6 h-6 overflow-hidden shrink-0 hidden"
                  alt=""
                  src="/icon--jamicons--outline--logos--plus4.svg"
                />
                <div className="flex flex-row py-0 px-4 items-center justify-center">
                  <div className="relative tracking-[0.5px] leading-[24px] font-medium">
                    Button Text
                  </div>
                </div>
                <img
                  className="relative w-6 h-6 overflow-hidden shrink-0"
                  alt=""
                  src="/icon--jamicons--outline--logos--arrowright4.svg"
                />
              </div>
            </div>
            <div className="my-0 mx-[!important] absolute top-[16px] left-[16px] rounded bg-purple-100 h-6 hidden flex-row py-2 px-1 box-border items-center justify-center z-[3] text-sm text-purple-800">
              <img
                className="relative w-4 h-4 overflow-hidden shrink-0 hidden"
                alt=""
                src="/icon--jamicons--outline--logos--circle1.svg"
              />
              <div className="flex flex-row py-0 px-1 items-center justify-center">
                <div className="relative leading-[140%]">Featured</div>
              </div>
              <img
                className="relative w-4 h-4 overflow-hidden shrink-0 hidden"
                alt=""
                src="/icon--iconoir--cancel.svg"
              />
            </div>
            <img
              className="my-0 mx-[!important] absolute top-[172px] left-[calc(50%_-_48.2px)] rounded-81xl w-24 h-24 object-cover hidden z-[4]"
              alt=""
              src="/user-thumb1@2x.png"
            />
          </div>
          <div className="flex-1 rounded-8xs h-[354px] overflow-hidden flex flex-col items-start justify-start relative bg-[url(/public/content-box1@3x.png)] bg-cover bg-no-repeat bg-[top] sm:flex-[unset] sm:self-stretch">
            <img
              className="self-stretch max-w-full overflow-hidden max-h-full object-cover hidden z-[0]"
              alt=""
              src="/picture2@2x.png"
            />
            <div className="self-stretch [background:linear-gradient(180deg,_rgba(15,_23,_42,_0.5),_rgba(15,_23,_42,_0))] flex flex-col pt-6 px-6 pb-16 items-start justify-start gap-[16px] z-[1]">
              <div className="hidden flex-row items-center justify-center">
                <img
                  className="relative w-16 h-16 overflow-hidden shrink-0 hidden"
                  alt=""
                  src="/icon--iconoir--threestars1.svg"
                />
              </div>
              <div className="self-stretch flex flex-col items-start justify-start gap-[4px] text-base text-white">
                <div className="self-stretch relative leading-[110%] font-medium hidden">
                  Текстиль
                </div>
                <div className="self-stretch flex flex-row items-center justify-start gap-[8px] text-5xl">
                  <b className="flex-1 relative leading-[110%]">Одежда</b>
                  <div className="rounded bg-green-100 h-6 hidden flex-row py-2 px-1 box-border items-center justify-center text-sm text-green-800">
                    <img
                      className="relative w-4 h-4 overflow-hidden shrink-0 hidden"
                      alt=""
                      src="/icon--jamicons--outline--logos--circle2.svg"
                    />
                    <div className="flex flex-row py-0 px-1 items-center justify-center">
                      <div className="relative leading-[140%]">+2,5%</div>
                    </div>
                    <img
                      className="relative w-4 h-4 overflow-hidden shrink-0"
                      alt=""
                      src="/icon--iconoir--statup1.svg"
                    />
                  </div>
                  <img
                    className="relative w-6 h-6 overflow-hidden shrink-0 hidden"
                    alt=""
                    src="/icon--jamicons--outline--logos--pencil1.svg"
                  />
                </div>
              </div>
              <img
                className="self-stretch max-w-full overflow-hidden h-[220px] shrink-0 object-cover hidden"
                alt=""
                src="/picture3@2x.png"
              />
              <div className="self-stretch relative leading-[160%] hidden">
                Vehicula sit sit pharetra bibendum ut risus accumsan. Purus, in
                metus, enim, ipsum morbi euismod pellentesque. Mattis pharetra
                accumsan eget est mi enim, id. Sit quam tortor eu tellus non, in
                euismod integer.
              </div>
              <div className="self-stretch hidden flex-row pt-4 px-0 pb-0 items-center justify-start gap-[16px]">
                <img
                  className="rounded-81xl w-16 h-16 object-cover"
                  alt=""
                  src="/user-thumb2@2x.png"
                />
                <div className="flex-1 flex flex-col items-start justify-start">
                  <div className="self-stretch relative leading-[160%]">
                    Ralph Edwards
                  </div>
                  <div className="self-stretch relative text-base leading-[140%] text-blue-gray-600">{`Harvesting 32KWh `}</div>
                </div>
              </div>
            </div>
            <div className="self-stretch hidden flex-row p-4 items-start justify-start gap-[16px] z-[2] text-base text-white">
              <div className="flex-1 rounded-lg bg-blue-600 flex flex-row p-3 items-center justify-center border-[2px] border-solid border-blue-600">
                <img
                  className="relative w-6 h-6 overflow-hidden shrink-0 hidden"
                  alt=""
                  src="/icon--jamicons--outline--logos--plus5.svg"
                />
                <div className="flex flex-row py-0 px-4 items-center justify-center">
                  <div className="relative tracking-[0.5px] leading-[24px] font-medium">
                    Button Text
                  </div>
                </div>
                <img
                  className="relative w-6 h-6 overflow-hidden shrink-0"
                  alt=""
                  src="/icon--jamicons--outline--logos--arrowright5.svg"
                />
              </div>
              <div className="flex-1 rounded-lg flex flex-row p-3 items-center justify-center text-blue-600 border-[2px] border-solid border-blue-600">
                <img
                  className="relative w-6 h-6 overflow-hidden shrink-0 hidden"
                  alt=""
                  src="/icon--jamicons--outline--logos--plus6.svg"
                />
                <div className="flex flex-row py-0 px-4 items-center justify-center">
                  <div className="relative tracking-[0.5px] leading-[24px] font-medium">
                    Button Text
                  </div>
                </div>
                <img
                  className="relative w-6 h-6 overflow-hidden shrink-0"
                  alt=""
                  src="/icon--jamicons--outline--logos--arrowright6.svg"
                />
              </div>
            </div>
            <div className="my-0 mx-[!important] absolute top-[16px] left-[16px] rounded bg-purple-100 h-6 hidden flex-row py-2 px-1 box-border items-center justify-center z-[3] text-sm text-purple-800">
              <img
                className="relative w-4 h-4 overflow-hidden shrink-0 hidden"
                alt=""
                src="/icon--jamicons--outline--logos--circle3.svg"
              />
              <div className="flex flex-row py-0 px-1 items-center justify-center">
                <div className="relative leading-[140%]">Featured</div>
              </div>
              <img
                className="relative w-4 h-4 overflow-hidden shrink-0 hidden"
                alt=""
                src="/icon--iconoir--cancel1.svg"
              />
            </div>
            <img
              className="my-0 mx-[!important] absolute top-[172px] left-[calc(50%_-_48.2px)] rounded-81xl w-24 h-24 object-cover hidden z-[4]"
              alt=""
              src="/user-thumb3@2x.png"
            />
          </div>
          <div className="flex-1 rounded-8xs h-[354px] overflow-hidden flex flex-col items-start justify-start relative bg-[url(/public/content-box2@3x.png)] bg-cover bg-no-repeat bg-[top] sm:flex-[unset] sm:self-stretch">
            <img
              className="self-stretch max-w-full overflow-hidden max-h-full object-cover hidden z-[0]"
              alt=""
              src="/picture4@2x.png"
            />
            <div className="self-stretch [background:linear-gradient(180deg,_rgba(15,_23,_42,_0.5),_rgba(15,_23,_42,_0))] flex flex-col pt-6 px-6 pb-16 items-start justify-start gap-[16px] z-[1]">
              <div className="hidden flex-row items-center justify-center">
                <img
                  className="relative w-16 h-16 overflow-hidden shrink-0 hidden"
                  alt=""
                  src="/icon--iconoir--threestars2.svg"
                />
              </div>
              <div className="self-stretch flex flex-col items-start justify-start gap-[4px] text-base text-white">
                <div className="self-stretch relative leading-[110%] font-medium hidden">
                  Текстиль
                </div>
                <div className="self-stretch flex flex-row items-center justify-start gap-[8px] text-5xl">
                  <b className="flex-1 relative leading-[110%]">{`Обувь `}</b>
                  <div className="rounded bg-green-100 h-6 hidden flex-row py-2 px-1 box-border items-center justify-center text-sm text-green-800">
                    <img
                      className="relative w-4 h-4 overflow-hidden shrink-0 hidden"
                      alt=""
                      src="/icon--jamicons--outline--logos--circle4.svg"
                    />
                    <div className="flex flex-row py-0 px-1 items-center justify-center">
                      <div className="relative leading-[140%]">+2,5%</div>
                    </div>
                    <img
                      className="relative w-4 h-4 overflow-hidden shrink-0"
                      alt=""
                      src="/icon--iconoir--statup2.svg"
                    />
                  </div>
                  <img
                    className="relative w-6 h-6 overflow-hidden shrink-0 hidden"
                    alt=""
                    src="/icon--jamicons--outline--logos--pencil2.svg"
                  />
                </div>
              </div>
              <img
                className="self-stretch max-w-full overflow-hidden h-[220px] shrink-0 object-cover hidden"
                alt=""
                src="/picture5@2x.png"
              />
              <div className="self-stretch relative leading-[160%] hidden">
                Vehicula sit sit pharetra bibendum ut risus accumsan. Purus, in
                metus, enim, ipsum morbi euismod pellentesque. Mattis pharetra
                accumsan eget est mi enim, id. Sit quam tortor eu tellus non, in
                euismod integer.
              </div>
              <div className="self-stretch hidden flex-row pt-4 px-0 pb-0 items-center justify-start gap-[16px]">
                <img
                  className="rounded-81xl w-16 h-16 object-cover"
                  alt=""
                  src="/user-thumb4@2x.png"
                />
                <div className="flex-1 flex flex-col items-start justify-start">
                  <div className="self-stretch relative leading-[160%]">
                    Ralph Edwards
                  </div>
                  <div className="self-stretch relative text-base leading-[140%] text-blue-gray-600">{`Harvesting 32KWh `}</div>
                </div>
              </div>
            </div>
            <div className="self-stretch hidden flex-row p-4 items-start justify-start gap-[16px] z-[2] text-base text-white">
              <div className="flex-1 rounded-lg bg-blue-600 flex flex-row p-3 items-center justify-center border-[2px] border-solid border-blue-600">
                <img
                  className="relative w-6 h-6 overflow-hidden shrink-0 hidden"
                  alt=""
                  src="/icon--jamicons--outline--logos--plus7.svg"
                />
                <div className="flex flex-row py-0 px-4 items-center justify-center">
                  <div className="relative tracking-[0.5px] leading-[24px] font-medium">
                    Button Text
                  </div>
                </div>
                <img
                  className="relative w-6 h-6 overflow-hidden shrink-0"
                  alt=""
                  src="/icon--jamicons--outline--logos--arrowright7.svg"
                />
              </div>
              <div className="flex-1 rounded-lg flex flex-row p-3 items-center justify-center text-blue-600 border-[2px] border-solid border-blue-600">
                <img
                  className="relative w-6 h-6 overflow-hidden shrink-0 hidden"
                  alt=""
                  src="/icon--jamicons--outline--logos--plus8.svg"
                />
                <div className="flex flex-row py-0 px-4 items-center justify-center">
                  <div className="relative tracking-[0.5px] leading-[24px] font-medium">
                    Button Text
                  </div>
                </div>
                <img
                  className="relative w-6 h-6 overflow-hidden shrink-0"
                  alt=""
                  src="/icon--jamicons--outline--logos--arrowright8.svg"
                />
              </div>
            </div>
            <div className="my-0 mx-[!important] absolute top-[16px] left-[16px] rounded bg-purple-100 h-6 hidden flex-row py-2 px-1 box-border items-center justify-center z-[3] text-sm text-purple-800">
              <img
                className="relative w-4 h-4 overflow-hidden shrink-0 hidden"
                alt=""
                src="/icon--jamicons--outline--logos--circle5.svg"
              />
              <div className="flex flex-row py-0 px-1 items-center justify-center">
                <div className="relative leading-[140%]">Featured</div>
              </div>
              <img
                className="relative w-4 h-4 overflow-hidden shrink-0 hidden"
                alt=""
                src="/icon--iconoir--cancel2.svg"
              />
            </div>
            <img
              className="my-0 mx-[!important] absolute top-[172px] left-[calc(50%_-_48.2px)] rounded-81xl w-24 h-24 object-cover hidden z-[4]"
              alt=""
              src="/user-thumb5@2x.png"
            />
          </div>
          <div className="flex-1 rounded-8xs h-[354px] overflow-hidden flex flex-col items-start justify-start relative bg-[url(/public/content-box3@3x.png)] bg-cover bg-no-repeat bg-[top] sm:flex-[unset] sm:self-stretch">
            <img
              className="self-stretch max-w-full overflow-hidden max-h-full object-cover hidden z-[0]"
              alt=""
              src="/picture6@2x.png"
            />
            <div className="self-stretch [background:linear-gradient(180deg,_rgba(15,_23,_42,_0.5),_rgba(15,_23,_42,_0))] flex flex-col pt-6 px-6 pb-16 items-start justify-start gap-[16px] z-[1]">
              <div className="hidden flex-row items-center justify-center">
                <img
                  className="relative w-16 h-16 overflow-hidden shrink-0 hidden"
                  alt=""
                  src="/icon--iconoir--threestars3.svg"
                />
              </div>
              <div className="self-stretch flex flex-col items-start justify-start gap-[4px] text-base text-white">
                <div className="self-stretch relative leading-[110%] font-medium hidden">
                  Текстиль
                </div>
                <div className="self-stretch flex flex-row items-center justify-start gap-[8px] text-5xl">
                  <b className="flex-1 relative leading-[110%]">
                    Текстиль для отеля
                  </b>
                  <div className="rounded bg-green-100 h-6 hidden flex-row py-2 px-1 box-border items-center justify-center text-sm text-green-800">
                    <img
                      className="relative w-4 h-4 overflow-hidden shrink-0 hidden"
                      alt=""
                      src="/icon--jamicons--outline--logos--circle6.svg"
                    />
                    <div className="flex flex-row py-0 px-1 items-center justify-center">
                      <div className="relative leading-[140%]">+2,5%</div>
                    </div>
                    <img
                      className="relative w-4 h-4 overflow-hidden shrink-0"
                      alt=""
                      src="/icon--iconoir--statup3.svg"
                    />
                  </div>
                  <img
                    className="relative w-6 h-6 overflow-hidden shrink-0 hidden"
                    alt=""
                    src="/icon--jamicons--outline--logos--pencil3.svg"
                  />
                </div>
              </div>
              <img
                className="self-stretch max-w-full overflow-hidden h-[220px] shrink-0 object-cover hidden"
                alt=""
                src="/picture7@2x.png"
              />
              <div className="self-stretch relative leading-[160%] hidden">
                Vehicula sit sit pharetra bibendum ut risus accumsan. Purus, in
                metus, enim, ipsum morbi euismod pellentesque. Mattis pharetra
                accumsan eget est mi enim, id. Sit quam tortor eu tellus non, in
                euismod integer.
              </div>
              <div className="self-stretch hidden flex-row pt-4 px-0 pb-0 items-center justify-start gap-[16px]">
                <img
                  className="rounded-81xl w-16 h-16 object-cover"
                  alt=""
                  src="/user-thumb6@2x.png"
                />
                <div className="flex-1 flex flex-col items-start justify-start">
                  <div className="self-stretch relative leading-[160%]">
                    Ralph Edwards
                  </div>
                  <div className="self-stretch relative text-base leading-[140%] text-blue-gray-600">{`Harvesting 32KWh `}</div>
                </div>
              </div>
            </div>
            <div className="self-stretch hidden flex-row p-4 items-start justify-start gap-[16px] z-[2] text-base text-white">
              <div className="flex-1 rounded-lg bg-blue-600 flex flex-row p-3 items-center justify-center border-[2px] border-solid border-blue-600">
                <img
                  className="relative w-6 h-6 overflow-hidden shrink-0 hidden"
                  alt=""
                  src="/icon--jamicons--outline--logos--plus9.svg"
                />
                <div className="flex flex-row py-0 px-4 items-center justify-center">
                  <div className="relative tracking-[0.5px] leading-[24px] font-medium">
                    Button Text
                  </div>
                </div>
                <img
                  className="relative w-6 h-6 overflow-hidden shrink-0"
                  alt=""
                  src="/icon--jamicons--outline--logos--arrowright9.svg"
                />
              </div>
              <div className="flex-1 rounded-lg flex flex-row p-3 items-center justify-center text-blue-600 border-[2px] border-solid border-blue-600">
                <img
                  className="relative w-6 h-6 overflow-hidden shrink-0 hidden"
                  alt=""
                  src="/icon--jamicons--outline--logos--plus10.svg"
                />
                <div className="flex flex-row py-0 px-4 items-center justify-center">
                  <div className="relative tracking-[0.5px] leading-[24px] font-medium">
                    Button Text
                  </div>
                </div>
                <img
                  className="relative w-6 h-6 overflow-hidden shrink-0"
                  alt=""
                  src="/icon--jamicons--outline--logos--arrowright10.svg"
                />
              </div>
            </div>
            <div className="my-0 mx-[!important] absolute top-[16px] left-[16px] rounded bg-purple-100 h-6 hidden flex-row py-2 px-1 box-border items-center justify-center z-[3] text-sm text-purple-800">
              <img
                className="relative w-4 h-4 overflow-hidden shrink-0 hidden"
                alt=""
                src="/icon--jamicons--outline--logos--circle7.svg"
              />
              <div className="flex flex-row py-0 px-1 items-center justify-center">
                <div className="relative leading-[140%]">Featured</div>
              </div>
              <img
                className="relative w-4 h-4 overflow-hidden shrink-0 hidden"
                alt=""
                src="/icon--iconoir--cancel3.svg"
              />
            </div>
            <img
              className="my-0 mx-[!important] absolute top-[172px] left-[calc(50%_-_48.2px)] rounded-81xl w-24 h-24 object-cover hidden z-[4]"
              alt=""
              src="/user-thumb7@2x.png"
            />
          </div>
          <div className="flex-1 rounded-8xs h-[354px] overflow-hidden flex flex-col items-start justify-start relative bg-[url(/public/content-box4@3x.png)] bg-cover bg-no-repeat bg-[top] sm:flex-[unset] sm:self-stretch">
            <img
              className="self-stretch max-w-full overflow-hidden max-h-full object-cover hidden z-[0]"
              alt=""
              src="/picture8@2x.png"
            />
            <div className="self-stretch [background:linear-gradient(180deg,_rgba(15,_23,_42,_0.5),_rgba(15,_23,_42,_0))] flex flex-col pt-6 px-6 pb-16 items-start justify-start gap-[16px] z-[1]">
              <div className="hidden flex-row items-center justify-center">
                <img
                  className="relative w-16 h-16 overflow-hidden shrink-0 hidden"
                  alt=""
                  src="/icon--iconoir--threestars4.svg"
                />
              </div>
              <div className="self-stretch flex flex-col items-start justify-start gap-[4px] text-base text-white">
                <div className="self-stretch relative leading-[110%] font-medium hidden">
                  Текстиль
                </div>
                <div className="self-stretch flex flex-row items-center justify-start gap-[8px] text-5xl">
                  <b className="flex-1 relative leading-[110%]">Косметика</b>
                  <div className="rounded bg-green-100 h-6 hidden flex-row py-2 px-1 box-border items-center justify-center text-sm text-green-800">
                    <img
                      className="relative w-4 h-4 overflow-hidden shrink-0 hidden"
                      alt=""
                      src="/icon--jamicons--outline--logos--circle8.svg"
                    />
                    <div className="flex flex-row py-0 px-1 items-center justify-center">
                      <div className="relative leading-[140%]">+2,5%</div>
                    </div>
                    <img
                      className="relative w-4 h-4 overflow-hidden shrink-0"
                      alt=""
                      src="/icon--iconoir--statup4.svg"
                    />
                  </div>
                  <img
                    className="relative w-6 h-6 overflow-hidden shrink-0 hidden"
                    alt=""
                    src="/icon--jamicons--outline--logos--pencil4.svg"
                  />
                </div>
              </div>
              <img
                className="self-stretch max-w-full overflow-hidden h-[220px] shrink-0 object-cover hidden"
                alt=""
                src="/picture9@2x.png"
              />
              <div className="self-stretch relative leading-[160%] hidden">
                Vehicula sit sit pharetra bibendum ut risus accumsan. Purus, in
                metus, enim, ipsum morbi euismod pellentesque. Mattis pharetra
                accumsan eget est mi enim, id. Sit quam tortor eu tellus non, in
                euismod integer.
              </div>
              <div className="self-stretch hidden flex-row pt-4 px-0 pb-0 items-center justify-start gap-[16px]">
                <img
                  className="rounded-81xl w-16 h-16 object-cover"
                  alt=""
                  src="/user-thumb8@2x.png"
                />
                <div className="flex-1 flex flex-col items-start justify-start">
                  <div className="self-stretch relative leading-[160%]">
                    Ralph Edwards
                  </div>
                  <div className="self-stretch relative text-base leading-[140%] text-blue-gray-600">{`Harvesting 32KWh `}</div>
                </div>
              </div>
            </div>
            <div className="self-stretch hidden flex-row p-4 items-start justify-start gap-[16px] z-[2] text-base text-white">
              <div className="flex-1 rounded-lg bg-blue-600 flex flex-row p-3 items-center justify-center border-[2px] border-solid border-blue-600">
                <img
                  className="relative w-6 h-6 overflow-hidden shrink-0 hidden"
                  alt=""
                  src="/icon--jamicons--outline--logos--plus11.svg"
                />
                <div className="flex flex-row py-0 px-4 items-center justify-center">
                  <div className="relative tracking-[0.5px] leading-[24px] font-medium">
                    Button Text
                  </div>
                </div>
                <img
                  className="relative w-6 h-6 overflow-hidden shrink-0"
                  alt=""
                  src="/icon--jamicons--outline--logos--arrowright11.svg"
                />
              </div>
              <div className="flex-1 rounded-lg flex flex-row p-3 items-center justify-center text-blue-600 border-[2px] border-solid border-blue-600">
                <img
                  className="relative w-6 h-6 overflow-hidden shrink-0 hidden"
                  alt=""
                  src="/icon--jamicons--outline--logos--plus12.svg"
                />
                <div className="flex flex-row py-0 px-4 items-center justify-center">
                  <div className="relative tracking-[0.5px] leading-[24px] font-medium">
                    Button Text
                  </div>
                </div>
                <img
                  className="relative w-6 h-6 overflow-hidden shrink-0"
                  alt=""
                  src="/icon--jamicons--outline--logos--arrowright12.svg"
                />
              </div>
            </div>
            <div className="my-0 mx-[!important] absolute top-[16px] left-[16px] rounded bg-purple-100 h-6 hidden flex-row py-2 px-1 box-border items-center justify-center z-[3] text-sm text-purple-800">
              <img
                className="relative w-4 h-4 overflow-hidden shrink-0 hidden"
                alt=""
                src="/icon--jamicons--outline--logos--circle9.svg"
              />
              <div className="flex flex-row py-0 px-1 items-center justify-center">
                <div className="relative leading-[140%]">Featured</div>
              </div>
              <img
                className="relative w-4 h-4 overflow-hidden shrink-0 hidden"
                alt=""
                src="/icon--iconoir--cancel4.svg"
              />
            </div>
            <img
              className="my-0 mx-[!important] absolute top-[172px] left-[calc(50%_-_48.2px)] rounded-81xl w-24 h-24 object-cover hidden z-[4]"
              alt=""
              src="/user-thumb9@2x.png"
            />
          </div>
        </div>
        <div className="self-stretch flex flex-row items-center justify-center gap-[24px] sm:flex-col">
          <div className="flex-1 rounded-8xs h-[354px] overflow-hidden flex flex-col items-start justify-start relative bg-[url(/public/content-box5@3x.png)] bg-cover bg-no-repeat bg-[top] sm:flex-[unset] sm:self-stretch">
            <img
              className="self-stretch max-w-full overflow-hidden max-h-full object-cover hidden z-[0]"
              alt=""
              src="/picture@2x.png"
            />
            <div className="self-stretch [background:linear-gradient(180deg,_rgba(15,_23,_42,_0.5),_rgba(15,_23,_42,_0))] flex flex-col pt-6 px-6 pb-16 items-start justify-start gap-[16px] z-[1]">
              <div className="hidden flex-row items-center justify-center">
                <img
                  className="relative w-16 h-16 overflow-hidden shrink-0 hidden"
                  alt=""
                  src="/icon--iconoir--threestars5.svg"
                />
              </div>
              <div className="self-stretch flex flex-col items-start justify-start gap-[4px] text-base text-white">
                <div className="self-stretch relative leading-[110%] font-medium hidden">
                  Продукты питания
                </div>
                <div className="self-stretch flex flex-row items-center justify-start gap-[8px] text-5xl">
                  <b className="flex-1 relative leading-[110%]">Фрукты</b>
                  <div className="rounded bg-green-100 h-6 hidden flex-row py-2 px-1 box-border items-center justify-center text-sm text-green-800">
                    <img
                      className="relative w-4 h-4 overflow-hidden shrink-0 hidden"
                      alt=""
                      src="/icon--jamicons--outline--logos--circle10.svg"
                    />
                    <div className="flex flex-row py-0 px-1 items-center justify-center">
                      <div className="relative leading-[140%]">+2,5%</div>
                    </div>
                    <img
                      className="relative w-4 h-4 overflow-hidden shrink-0"
                      alt=""
                      src="/icon--iconoir--statup5.svg"
                    />
                  </div>
                  <img
                    className="relative w-6 h-6 overflow-hidden shrink-0 hidden"
                    alt=""
                    src="/icon--jamicons--outline--logos--pencil.svg"
                  />
                </div>
              </div>
              <img
                className="self-stretch max-w-full overflow-hidden h-[220px] shrink-0 object-cover hidden"
                alt=""
                src="/picture1@2x.png"
              />
              <div className="self-stretch relative leading-[160%] hidden">
                Vehicula sit sit pharetra bibendum ut risus accumsan. Purus, in
                metus, enim, ipsum morbi euismod pellentesque. Mattis pharetra
                accumsan eget est mi enim, id. Sit quam tortor eu tellus non, in
                euismod integer.
              </div>
              <div className="self-stretch hidden flex-row pt-4 px-0 pb-0 items-center justify-start gap-[16px]">
                <img
                  className="rounded-81xl w-16 h-16 object-cover"
                  alt=""
                  src="/user-thumb@2x.png"
                />
                <div className="flex-1 flex flex-col items-start justify-start">
                  <div className="self-stretch relative leading-[160%]">
                    Ralph Edwards
                  </div>
                  <div className="self-stretch relative text-base leading-[140%] text-blue-gray-600">{`Harvesting 32KWh `}</div>
                </div>
              </div>
            </div>
            <div className="self-stretch hidden flex-row p-4 items-start justify-start gap-[16px] z-[2] text-base text-white">
              <div className="flex-1 rounded-lg bg-blue-600 flex flex-row p-3 items-center justify-center border-[2px] border-solid border-blue-600">
                <img
                  className="relative w-6 h-6 overflow-hidden shrink-0 hidden"
                  alt=""
                  src="/icon--jamicons--outline--logos--plus13.svg"
                />
                <div className="flex flex-row py-0 px-4 items-center justify-center">
                  <div className="relative tracking-[0.5px] leading-[24px] font-medium">
                    Button Text
                  </div>
                </div>
                <img
                  className="relative w-6 h-6 overflow-hidden shrink-0"
                  alt=""
                  src="/icon--jamicons--outline--logos--arrowright13.svg"
                />
              </div>
              <div className="flex-1 rounded-lg flex flex-row p-3 items-center justify-center text-blue-600 border-[2px] border-solid border-blue-600">
                <img
                  className="relative w-6 h-6 overflow-hidden shrink-0 hidden"
                  alt=""
                  src="/icon--jamicons--outline--logos--plus14.svg"
                />
                <div className="flex flex-row py-0 px-4 items-center justify-center">
                  <div className="relative tracking-[0.5px] leading-[24px] font-medium">
                    Button Text
                  </div>
                </div>
                <img
                  className="relative w-6 h-6 overflow-hidden shrink-0"
                  alt=""
                  src="/icon--jamicons--outline--logos--arrowright14.svg"
                />
              </div>
            </div>
            <div className="my-0 mx-[!important] absolute top-[16px] left-[16px] rounded bg-purple-100 h-6 hidden flex-row py-2 px-1 box-border items-center justify-center z-[3] text-sm text-purple-800">
              <img
                className="relative w-4 h-4 overflow-hidden shrink-0 hidden"
                alt=""
                src="/icon--jamicons--outline--logos--circle11.svg"
              />
              <div className="flex flex-row py-0 px-1 items-center justify-center">
                <div className="relative leading-[140%]">Featured</div>
              </div>
              <img
                className="relative w-4 h-4 overflow-hidden shrink-0 hidden"
                alt=""
                src="/icon--iconoir--cancel5.svg"
              />
            </div>
            <img
              className="my-0 mx-[!important] absolute top-[172px] left-[calc(50%_-_48.2px)] rounded-81xl w-24 h-24 object-cover hidden z-[4]"
              alt=""
              src="/user-thumb1@2x.png"
            />
          </div>
          <div className="flex-1 rounded-8xs h-[354px] overflow-hidden flex flex-col items-start justify-start relative bg-[url(/public/content-box6@3x.png)] bg-cover bg-no-repeat bg-[top] sm:flex-[unset] sm:self-stretch">
            <img
              className="self-stretch max-w-full overflow-hidden max-h-full object-cover hidden z-[0]"
              alt=""
              src="/picture2@2x.png"
            />
            <div className="self-stretch [background:linear-gradient(180deg,_rgba(15,_23,_42,_0.5),_rgba(15,_23,_42,_0))] flex flex-col pt-6 px-6 pb-16 items-start justify-start gap-[16px] z-[1]">
              <div className="hidden flex-row items-center justify-center">
                <img
                  className="relative w-16 h-16 overflow-hidden shrink-0 hidden"
                  alt=""
                  src="/icon--iconoir--threestars6.svg"
                />
              </div>
              <div className="self-stretch flex flex-col items-start justify-start gap-[4px] text-base text-white">
                <div className="self-stretch relative leading-[110%] font-medium hidden">
                  Продукты питания
                </div>
                <div className="self-stretch flex flex-row items-center justify-start gap-[8px] text-5xl">
                  <b className="flex-1 relative leading-[110%]">
                    Молочные продукты
                  </b>
                  <div className="rounded bg-green-100 h-6 hidden flex-row py-2 px-1 box-border items-center justify-center text-sm text-green-800">
                    <img
                      className="relative w-4 h-4 overflow-hidden shrink-0 hidden"
                      alt=""
                      src="/icon--jamicons--outline--logos--circle12.svg"
                    />
                    <div className="flex flex-row py-0 px-1 items-center justify-center">
                      <div className="relative leading-[140%]">+2,5%</div>
                    </div>
                    <img
                      className="relative w-4 h-4 overflow-hidden shrink-0"
                      alt=""
                      src="/icon--iconoir--statup6.svg"
                    />
                  </div>
                  <img
                    className="relative w-6 h-6 overflow-hidden shrink-0 hidden"
                    alt=""
                    src="/icon--jamicons--outline--logos--pencil5.svg"
                  />
                </div>
              </div>
              <img
                className="self-stretch max-w-full overflow-hidden h-[220px] shrink-0 object-cover hidden"
                alt=""
                src="/picture3@2x.png"
              />
              <div className="self-stretch relative leading-[160%] hidden">
                Vehicula sit sit pharetra bibendum ut risus accumsan. Purus, in
                metus, enim, ipsum morbi euismod pellentesque. Mattis pharetra
                accumsan eget est mi enim, id. Sit quam tortor eu tellus non, in
                euismod integer.
              </div>
              <div className="self-stretch hidden flex-row pt-4 px-0 pb-0 items-center justify-start gap-[16px]">
                <img
                  className="rounded-81xl w-16 h-16 object-cover"
                  alt=""
                  src="/user-thumb2@2x.png"
                />
                <div className="flex-1 flex flex-col items-start justify-start">
                  <div className="self-stretch relative leading-[160%]">
                    Ralph Edwards
                  </div>
                  <div className="self-stretch relative text-base leading-[140%] text-blue-gray-600">{`Harvesting 32KWh `}</div>
                </div>
              </div>
            </div>
            <div className="self-stretch hidden flex-row p-4 items-start justify-start gap-[16px] z-[2] text-base text-white">
              <div className="flex-1 rounded-lg bg-blue-600 flex flex-row p-3 items-center justify-center border-[2px] border-solid border-blue-600">
                <img
                  className="relative w-6 h-6 overflow-hidden shrink-0 hidden"
                  alt=""
                  src="/icon--jamicons--outline--logos--plus15.svg"
                />
                <div className="flex flex-row py-0 px-4 items-center justify-center">
                  <div className="relative tracking-[0.5px] leading-[24px] font-medium">
                    Button Text
                  </div>
                </div>
                <img
                  className="relative w-6 h-6 overflow-hidden shrink-0"
                  alt=""
                  src="/icon--jamicons--outline--logos--arrowright15.svg"
                />
              </div>
              <div className="flex-1 rounded-lg flex flex-row p-3 items-center justify-center text-blue-600 border-[2px] border-solid border-blue-600">
                <img
                  className="relative w-6 h-6 overflow-hidden shrink-0 hidden"
                  alt=""
                  src="/icon--jamicons--outline--logos--plus16.svg"
                />
                <div className="flex flex-row py-0 px-4 items-center justify-center">
                  <div className="relative tracking-[0.5px] leading-[24px] font-medium">
                    Button Text
                  </div>
                </div>
                <img
                  className="relative w-6 h-6 overflow-hidden shrink-0"
                  alt=""
                  src="/icon--jamicons--outline--logos--arrowright16.svg"
                />
              </div>
            </div>
            <div className="my-0 mx-[!important] absolute top-[16px] left-[16px] rounded bg-purple-100 h-6 hidden flex-row py-2 px-1 box-border items-center justify-center z-[3] text-sm text-purple-800">
              <img
                className="relative w-4 h-4 overflow-hidden shrink-0 hidden"
                alt=""
                src="/icon--jamicons--outline--logos--circle13.svg"
              />
              <div className="flex flex-row py-0 px-1 items-center justify-center">
                <div className="relative leading-[140%]">Featured</div>
              </div>
              <img
                className="relative w-4 h-4 overflow-hidden shrink-0 hidden"
                alt=""
                src="/icon--iconoir--cancel6.svg"
              />
            </div>
            <img
              className="my-0 mx-[!important] absolute top-[172px] left-[calc(50%_-_48.2px)] rounded-81xl w-24 h-24 object-cover hidden z-[4]"
              alt=""
              src="/user-thumb3@2x.png"
            />
          </div>
          <div className="flex-1 rounded-8xs h-[354px] overflow-hidden flex flex-col items-start justify-start relative bg-[url(/public/content-box7@3x.png)] bg-cover bg-no-repeat bg-[top] sm:flex-[unset] sm:self-stretch">
            <img
              className="self-stretch max-w-full overflow-hidden max-h-full object-cover hidden z-[0]"
              alt=""
              src="/picture4@2x.png"
            />
            <div className="self-stretch [background:linear-gradient(180deg,_rgba(15,_23,_42,_0.5),_rgba(15,_23,_42,_0))] flex flex-col pt-6 px-6 pb-16 items-start justify-start gap-[16px] z-[1]">
              <div className="hidden flex-row items-center justify-center">
                <img
                  className="relative w-16 h-16 overflow-hidden shrink-0 hidden"
                  alt=""
                  src="/icon--iconoir--threestars7.svg"
                />
              </div>
              <div className="self-stretch flex flex-col items-start justify-start gap-[4px] text-base text-white">
                <div className="self-stretch relative leading-[110%] font-medium hidden">
                  Продукты питания
                </div>
                <div className="self-stretch flex flex-row items-center justify-start gap-[8px] text-5xl">
                  <b className="flex-1 relative leading-[110%]">Готовая еда</b>
                  <div className="rounded bg-green-100 h-6 hidden flex-row py-2 px-1 box-border items-center justify-center text-sm text-green-800">
                    <img
                      className="relative w-4 h-4 overflow-hidden shrink-0 hidden"
                      alt=""
                      src="/icon--jamicons--outline--logos--circle14.svg"
                    />
                    <div className="flex flex-row py-0 px-1 items-center justify-center">
                      <div className="relative leading-[140%]">+2,5%</div>
                    </div>
                    <img
                      className="relative w-4 h-4 overflow-hidden shrink-0"
                      alt=""
                      src="/icon--iconoir--statup7.svg"
                    />
                  </div>
                  <img
                    className="relative w-6 h-6 overflow-hidden shrink-0 hidden"
                    alt=""
                    src="/icon--jamicons--outline--logos--pencil21.svg"
                  />
                </div>
              </div>
              <img
                className="self-stretch max-w-full overflow-hidden h-[220px] shrink-0 object-cover hidden"
                alt=""
                src="/picture5@2x.png"
              />
              <div className="self-stretch relative leading-[160%] hidden">
                Vehicula sit sit pharetra bibendum ut risus accumsan. Purus, in
                metus, enim, ipsum morbi euismod pellentesque. Mattis pharetra
                accumsan eget est mi enim, id. Sit quam tortor eu tellus non, in
                euismod integer.
              </div>
              <div className="self-stretch hidden flex-row pt-4 px-0 pb-0 items-center justify-start gap-[16px]">
                <img
                  className="rounded-81xl w-16 h-16 object-cover"
                  alt=""
                  src="/user-thumb4@2x.png"
                />
                <div className="flex-1 flex flex-col items-start justify-start">
                  <div className="self-stretch relative leading-[160%]">
                    Ralph Edwards
                  </div>
                  <div className="self-stretch relative text-base leading-[140%] text-blue-gray-600">{`Harvesting 32KWh `}</div>
                </div>
              </div>
            </div>
            <div className="self-stretch hidden flex-row p-4 items-start justify-start gap-[16px] z-[2] text-base text-white">
              <div className="flex-1 rounded-lg bg-blue-600 flex flex-row p-3 items-center justify-center border-[2px] border-solid border-blue-600">
                <img
                  className="relative w-6 h-6 overflow-hidden shrink-0 hidden"
                  alt=""
                  src="/icon--jamicons--outline--logos--plus17.svg"
                />
                <div className="flex flex-row py-0 px-4 items-center justify-center">
                  <div className="relative tracking-[0.5px] leading-[24px] font-medium">
                    Button Text
                  </div>
                </div>
                <img
                  className="relative w-6 h-6 overflow-hidden shrink-0"
                  alt=""
                  src="/icon--jamicons--outline--logos--arrowright17.svg"
                />
              </div>
              <div className="flex-1 rounded-lg flex flex-row p-3 items-center justify-center text-blue-600 border-[2px] border-solid border-blue-600">
                <img
                  className="relative w-6 h-6 overflow-hidden shrink-0 hidden"
                  alt=""
                  src="/icon--jamicons--outline--logos--plus18.svg"
                />
                <div className="flex flex-row py-0 px-4 items-center justify-center">
                  <div className="relative tracking-[0.5px] leading-[24px] font-medium">
                    Button Text
                  </div>
                </div>
                <img
                  className="relative w-6 h-6 overflow-hidden shrink-0"
                  alt=""
                  src="/icon--jamicons--outline--logos--arrowright18.svg"
                />
              </div>
            </div>
            <div className="my-0 mx-[!important] absolute top-[16px] left-[16px] rounded bg-purple-100 h-6 hidden flex-row py-2 px-1 box-border items-center justify-center z-[3] text-sm text-purple-800">
              <img
                className="relative w-4 h-4 overflow-hidden shrink-0 hidden"
                alt=""
                src="/icon--jamicons--outline--logos--circle15.svg"
              />
              <div className="flex flex-row py-0 px-1 items-center justify-center">
                <div className="relative leading-[140%]">Featured</div>
              </div>
              <img
                className="relative w-4 h-4 overflow-hidden shrink-0 hidden"
                alt=""
                src="/icon--iconoir--cancel7.svg"
              />
            </div>
            <img
              className="my-0 mx-[!important] absolute top-[172px] left-[calc(50%_-_48.2px)] rounded-81xl w-24 h-24 object-cover hidden z-[4]"
              alt=""
              src="/user-thumb5@2x.png"
            />
          </div>
          <div className="flex-1 rounded-8xs h-[354px] overflow-hidden flex flex-col items-start justify-start relative bg-[url(/public/content-box8@3x.png)] bg-cover bg-no-repeat bg-[top] sm:flex-[unset] sm:self-stretch">
            <img
              className="self-stretch max-w-full overflow-hidden max-h-full object-cover hidden z-[0]"
              alt=""
              src="/picture6@2x.png"
            />
            <div className="self-stretch [background:linear-gradient(180deg,_rgba(15,_23,_42,_0.5),_rgba(15,_23,_42,_0))] flex flex-col pt-6 px-6 pb-16 items-start justify-start gap-[16px] z-[1]">
              <div className="hidden flex-row items-center justify-center">
                <img
                  className="relative w-16 h-16 overflow-hidden shrink-0 hidden"
                  alt=""
                  src="/icon--iconoir--threestars8.svg"
                />
              </div>
              <div className="self-stretch flex flex-col items-start justify-start gap-[4px] text-base text-white">
                <div className="self-stretch relative leading-[110%] font-medium hidden">
                  Продукты питания
                </div>
                <div className="self-stretch flex flex-row items-center justify-start gap-[8px] text-5xl">
                  <b className="flex-1 relative leading-[110%]">
                    Чистящие средства
                  </b>
                  <div className="rounded bg-green-100 h-6 hidden flex-row py-2 px-1 box-border items-center justify-center text-sm text-green-800">
                    <img
                      className="relative w-4 h-4 overflow-hidden shrink-0 hidden"
                      alt=""
                      src="/icon--jamicons--outline--logos--circle16.svg"
                    />
                    <div className="flex flex-row py-0 px-1 items-center justify-center">
                      <div className="relative leading-[140%]">+2,5%</div>
                    </div>
                    <img
                      className="relative w-4 h-4 overflow-hidden shrink-0"
                      alt=""
                      src="/icon--iconoir--statup8.svg"
                    />
                  </div>
                  <img
                    className="relative w-6 h-6 overflow-hidden shrink-0 hidden"
                    alt=""
                    src="/icon--jamicons--outline--logos--pencil3.svg"
                  />
                </div>
              </div>
              <img
                className="self-stretch max-w-full overflow-hidden h-[220px] shrink-0 object-cover hidden"
                alt=""
                src="/picture7@2x.png"
              />
              <div className="self-stretch relative leading-[160%] hidden">
                Vehicula sit sit pharetra bibendum ut risus accumsan. Purus, in
                metus, enim, ipsum morbi euismod pellentesque. Mattis pharetra
                accumsan eget est mi enim, id. Sit quam tortor eu tellus non, in
                euismod integer.
              </div>
              <div className="self-stretch hidden flex-row pt-4 px-0 pb-0 items-center justify-start gap-[16px]">
                <img
                  className="rounded-81xl w-16 h-16 object-cover"
                  alt=""
                  src="/user-thumb6@2x.png"
                />
                <div className="flex-1 flex flex-col items-start justify-start">
                  <div className="self-stretch relative leading-[160%]">
                    Ralph Edwards
                  </div>
                  <div className="self-stretch relative text-base leading-[140%] text-blue-gray-600">{`Harvesting 32KWh `}</div>
                </div>
              </div>
            </div>
            <div className="self-stretch hidden flex-row p-4 items-start justify-start gap-[16px] z-[2] text-base text-white">
              <div className="flex-1 rounded-lg bg-blue-600 flex flex-row p-3 items-center justify-center border-[2px] border-solid border-blue-600">
                <img
                  className="relative w-6 h-6 overflow-hidden shrink-0 hidden"
                  alt=""
                  src="/icon--jamicons--outline--logos--plus19.svg"
                />
                <div className="flex flex-row py-0 px-4 items-center justify-center">
                  <div className="relative tracking-[0.5px] leading-[24px] font-medium">
                    Button Text
                  </div>
                </div>
                <img
                  className="relative w-6 h-6 overflow-hidden shrink-0"
                  alt=""
                  src="/icon--jamicons--outline--logos--arrowright19.svg"
                />
              </div>
              <div className="flex-1 rounded-lg flex flex-row p-3 items-center justify-center text-blue-600 border-[2px] border-solid border-blue-600">
                <img
                  className="relative w-6 h-6 overflow-hidden shrink-0 hidden"
                  alt=""
                  src="/icon--jamicons--outline--logos--plus20.svg"
                />
                <div className="flex flex-row py-0 px-4 items-center justify-center">
                  <div className="relative tracking-[0.5px] leading-[24px] font-medium">
                    Button Text
                  </div>
                </div>
                <img
                  className="relative w-6 h-6 overflow-hidden shrink-0"
                  alt=""
                  src="/icon--jamicons--outline--logos--arrowright20.svg"
                />
              </div>
            </div>
            <div className="my-0 mx-[!important] absolute top-[16px] left-[16px] rounded bg-purple-100 h-6 hidden flex-row py-2 px-1 box-border items-center justify-center z-[3] text-sm text-purple-800">
              <img
                className="relative w-4 h-4 overflow-hidden shrink-0 hidden"
                alt=""
                src="/icon--jamicons--outline--logos--circle17.svg"
              />
              <div className="flex flex-row py-0 px-1 items-center justify-center">
                <div className="relative leading-[140%]">Featured</div>
              </div>
              <img
                className="relative w-4 h-4 overflow-hidden shrink-0 hidden"
                alt=""
                src="/icon--iconoir--cancel8.svg"
              />
            </div>
            <img
              className="my-0 mx-[!important] absolute top-[172px] left-[calc(50%_-_48.2px)] rounded-81xl w-24 h-24 object-cover hidden z-[4]"
              alt=""
              src="/user-thumb7@2x.png"
            />
          </div>
          <div className="flex-1 rounded-8xs h-[354px] overflow-hidden flex flex-col items-start justify-start relative bg-[url(/public/content-box9@3x.png)] bg-cover bg-no-repeat bg-[top] sm:flex-[unset] sm:self-stretch">
            <img
              className="self-stretch max-w-full overflow-hidden max-h-full object-cover hidden z-[0]"
              alt=""
              src="/picture8@2x.png"
            />
            <div className="self-stretch [background:linear-gradient(180deg,_rgba(15,_23,_42,_0.5),_rgba(15,_23,_42,_0))] flex flex-col pt-6 px-6 pb-16 items-start justify-start gap-[16px] z-[1]">
              <div className="hidden flex-row items-center justify-center">
                <img
                  className="relative w-16 h-16 overflow-hidden shrink-0 hidden"
                  alt=""
                  src="/icon--iconoir--threestars9.svg"
                />
              </div>
              <div className="self-stretch flex flex-col items-start justify-start gap-[4px] text-base text-white">
                <div className="self-stretch relative leading-[110%] font-medium hidden">
                  Продукты питания
                </div>
                <div className="self-stretch flex flex-row items-center justify-start gap-[8px] text-5xl">
                  <b className="flex-1 relative leading-[110%]">Мебель</b>
                  <div className="rounded bg-green-100 h-6 hidden flex-row py-2 px-1 box-border items-center justify-center text-sm text-green-800">
                    <img
                      className="relative w-4 h-4 overflow-hidden shrink-0 hidden"
                      alt=""
                      src="/icon--jamicons--outline--logos--circle18.svg"
                    />
                    <div className="flex flex-row py-0 px-1 items-center justify-center">
                      <div className="relative leading-[140%]">+2,5%</div>
                    </div>
                    <img
                      className="relative w-4 h-4 overflow-hidden shrink-0"
                      alt=""
                      src="/icon--iconoir--statup9.svg"
                    />
                  </div>
                  <img
                    className="relative w-6 h-6 overflow-hidden shrink-0 hidden"
                    alt=""
                    src="/icon--jamicons--outline--logos--pencil41.svg"
                  />
                </div>
              </div>
              <img
                className="self-stretch max-w-full overflow-hidden h-[220px] shrink-0 object-cover hidden"
                alt=""
                src="/picture9@2x.png"
              />
              <div className="self-stretch relative leading-[160%] hidden">
                Vehicula sit sit pharetra bibendum ut risus accumsan. Purus, in
                metus, enim, ipsum morbi euismod pellentesque. Mattis pharetra
                accumsan eget est mi enim, id. Sit quam tortor eu tellus non, in
                euismod integer.
              </div>
              <div className="self-stretch hidden flex-row pt-4 px-0 pb-0 items-center justify-start gap-[16px]">
                <img
                  className="rounded-81xl w-16 h-16 object-cover"
                  alt=""
                  src="/user-thumb8@2x.png"
                />
                <div className="flex-1 flex flex-col items-start justify-start">
                  <div className="self-stretch relative leading-[160%]">
                    Ralph Edwards
                  </div>
                  <div className="self-stretch relative text-base leading-[140%] text-blue-gray-600">{`Harvesting 32KWh `}</div>
                </div>
              </div>
            </div>
            <div className="self-stretch hidden flex-row p-4 items-start justify-start gap-[16px] z-[2] text-base text-white">
              <div className="flex-1 rounded-lg bg-blue-600 flex flex-row p-3 items-center justify-center border-[2px] border-solid border-blue-600">
                <img
                  className="relative w-6 h-6 overflow-hidden shrink-0 hidden"
                  alt=""
                  src="/icon--jamicons--outline--logos--plus21.svg"
                />
                <div className="flex flex-row py-0 px-4 items-center justify-center">
                  <div className="relative tracking-[0.5px] leading-[24px] font-medium">
                    Button Text
                  </div>
                </div>
                <img
                  className="relative w-6 h-6 overflow-hidden shrink-0"
                  alt=""
                  src="/icon--jamicons--outline--logos--arrowright21.svg"
                />
              </div>
              <div className="flex-1 rounded-lg flex flex-row p-3 items-center justify-center text-blue-600 border-[2px] border-solid border-blue-600">
                <img
                  className="relative w-6 h-6 overflow-hidden shrink-0 hidden"
                  alt=""
                  src="/icon--jamicons--outline--logos--plus22.svg"
                />
                <div className="flex flex-row py-0 px-4 items-center justify-center">
                  <div className="relative tracking-[0.5px] leading-[24px] font-medium">
                    Button Text
                  </div>
                </div>
                <img
                  className="relative w-6 h-6 overflow-hidden shrink-0"
                  alt=""
                  src="/icon--jamicons--outline--logos--arrowright22.svg"
                />
              </div>
            </div>
            <div className="my-0 mx-[!important] absolute top-[16px] left-[16px] rounded bg-purple-100 h-6 hidden flex-row py-2 px-1 box-border items-center justify-center z-[3] text-sm text-purple-800">
              <img
                className="relative w-4 h-4 overflow-hidden shrink-0 hidden"
                alt=""
                src="/icon--jamicons--outline--logos--circle19.svg"
              />
              <div className="flex flex-row py-0 px-1 items-center justify-center">
                <div className="relative leading-[140%]">Featured</div>
              </div>
              <img
                className="relative w-4 h-4 overflow-hidden shrink-0 hidden"
                alt=""
                src="/icon--iconoir--cancel9.svg"
              />
            </div>
            <img
              className="my-0 mx-[!important] absolute top-[172px] left-[calc(50%_-_48.2px)] rounded-81xl w-24 h-24 object-cover hidden z-[4]"
              alt=""
              src="/user-thumb9@2x.png"
            />
          </div>
        </div>
        <div className="self-stretch flex flex-row items-center justify-center gap-[24px] sm:flex-col">
          <div className="flex-1 rounded-8xs h-[354px] overflow-hidden flex flex-col items-start justify-start relative bg-[url(/public/content-box10@3x.png)] bg-cover bg-no-repeat bg-[top] sm:flex-[unset] sm:self-stretch">
            <img
              className="self-stretch max-w-full overflow-hidden max-h-full object-cover hidden z-[0]"
              alt=""
              src="/picture@2x.png"
            />
            <div className="self-stretch [background:linear-gradient(180deg,_rgba(15,_23,_42,_0.5),_rgba(15,_23,_42,_0))] flex flex-col pt-6 px-6 pb-16 items-start justify-start gap-[16px] z-[1]">
              <div className="hidden flex-row items-center justify-center">
                <img
                  className="relative w-16 h-16 overflow-hidden shrink-0 hidden"
                  alt=""
                  src="/icon--iconoir--threestars5.svg"
                />
              </div>
              <div className="self-stretch flex flex-col items-start justify-start gap-[4px] text-base text-white">
                <div className="self-stretch relative leading-[110%] font-medium hidden">
                  Электрическая техника
                </div>
                <div className="self-stretch flex flex-row items-center justify-start gap-[8px] text-5xl">
                  <b className="flex-1 relative leading-[110%]">Электроника</b>
                  <div className="rounded bg-green-100 h-6 hidden flex-row py-2 px-1 box-border items-center justify-center text-sm text-green-800">
                    <img
                      className="relative w-4 h-4 overflow-hidden shrink-0 hidden"
                      alt=""
                      src="/icon--jamicons--outline--logos--circle10.svg"
                    />
                    <div className="flex flex-row py-0 px-1 items-center justify-center">
                      <div className="relative leading-[140%]">+2,5%</div>
                    </div>
                    <img
                      className="relative w-4 h-4 overflow-hidden shrink-0"
                      alt=""
                      src="/icon--iconoir--statup51.svg"
                    />
                  </div>
                  <img
                    className="relative w-6 h-6 overflow-hidden shrink-0 hidden"
                    alt=""
                    src="/icon--jamicons--outline--logos--pencil.svg"
                  />
                </div>
              </div>
              <img
                className="self-stretch max-w-full overflow-hidden h-[220px] shrink-0 object-cover hidden"
                alt=""
                src="/picture1@2x.png"
              />
              <div className="self-stretch relative leading-[160%] hidden">
                Vehicula sit sit pharetra bibendum ut risus accumsan. Purus, in
                metus, enim, ipsum morbi euismod pellentesque. Mattis pharetra
                accumsan eget est mi enim, id. Sit quam tortor eu tellus non, in
                euismod integer.
              </div>
              <div className="self-stretch hidden flex-row pt-4 px-0 pb-0 items-center justify-start gap-[16px]">
                <img
                  className="rounded-81xl w-16 h-16 object-cover"
                  alt=""
                  src="/user-thumb@2x.png"
                />
                <div className="flex-1 flex flex-col items-start justify-start">
                  <div className="self-stretch relative leading-[160%]">
                    Ralph Edwards
                  </div>
                  <div className="self-stretch relative text-base leading-[140%] text-blue-gray-600">{`Harvesting 32KWh `}</div>
                </div>
              </div>
            </div>
            <div className="self-stretch hidden flex-row p-4 items-start justify-start gap-[16px] z-[2] text-base text-white">
              <div className="flex-1 rounded-lg bg-blue-600 flex flex-row p-3 items-center justify-center border-[2px] border-solid border-blue-600">
                <img
                  className="relative w-6 h-6 overflow-hidden shrink-0 hidden"
                  alt=""
                  src="/icon--jamicons--outline--logos--plus13.svg"
                />
                <div className="flex flex-row py-0 px-4 items-center justify-center">
                  <div className="relative tracking-[0.5px] leading-[24px] font-medium">
                    Button Text
                  </div>
                </div>
                <img
                  className="relative w-6 h-6 overflow-hidden shrink-0"
                  alt=""
                  src="/icon--jamicons--outline--logos--arrowright13.svg"
                />
              </div>
              <div className="flex-1 rounded-lg flex flex-row p-3 items-center justify-center text-blue-600 border-[2px] border-solid border-blue-600">
                <img
                  className="relative w-6 h-6 overflow-hidden shrink-0 hidden"
                  alt=""
                  src="/icon--jamicons--outline--logos--plus14.svg"
                />
                <div className="flex flex-row py-0 px-4 items-center justify-center">
                  <div className="relative tracking-[0.5px] leading-[24px] font-medium">
                    Button Text
                  </div>
                </div>
                <img
                  className="relative w-6 h-6 overflow-hidden shrink-0"
                  alt=""
                  src="/icon--jamicons--outline--logos--arrowright14.svg"
                />
              </div>
            </div>
            <div className="my-0 mx-[!important] absolute top-[16px] left-[16px] rounded bg-purple-100 h-6 hidden flex-row py-2 px-1 box-border items-center justify-center z-[3] text-sm text-purple-800">
              <img
                className="relative w-4 h-4 overflow-hidden shrink-0 hidden"
                alt=""
                src="/icon--jamicons--outline--logos--circle20.svg"
              />
              <div className="flex flex-row py-0 px-1 items-center justify-center">
                <div className="relative leading-[140%]">Featured</div>
              </div>
              <img
                className="relative w-4 h-4 overflow-hidden shrink-0 hidden"
                alt=""
                src="/icon--iconoir--cancel10.svg"
              />
            </div>
            <img
              className="my-0 mx-[!important] absolute top-[172px] left-[calc(50%_-_48.2px)] rounded-81xl w-24 h-24 object-cover hidden z-[4]"
              alt=""
              src="/user-thumb1@2x.png"
            />
          </div>
          <div className="flex-1 rounded-8xs h-[354px] overflow-hidden flex flex-col items-start justify-start relative bg-[url(/public/content-box11@3x.png)] bg-cover bg-no-repeat bg-[top] sm:flex-[unset] sm:self-stretch">
            <img
              className="self-stretch max-w-full overflow-hidden max-h-full object-cover hidden z-[0]"
              alt=""
              src="/picture2@2x.png"
            />
            <div className="self-stretch [background:linear-gradient(180deg,_rgba(15,_23,_42,_0.5),_rgba(15,_23,_42,_0))] flex flex-col pt-6 px-6 pb-16 items-start justify-start gap-[16px] z-[1]">
              <div className="hidden flex-row items-center justify-center">
                <img
                  className="relative w-16 h-16 overflow-hidden shrink-0 hidden"
                  alt=""
                  src="/icon--iconoir--threestars6.svg"
                />
              </div>
              <div className="self-stretch flex flex-col items-start justify-start gap-[4px] text-base text-white">
                <div className="self-stretch relative leading-[110%] font-medium hidden">
                  Электрическая техника
                </div>
                <div className="self-stretch flex flex-row items-center justify-start gap-[8px] text-5xl">
                  <b className="flex-1 relative leading-[110%]">
                    Металлические изделия
                  </b>
                  <div className="rounded bg-green-100 h-6 hidden flex-row py-2 px-1 box-border items-center justify-center text-sm text-green-800">
                    <img
                      className="relative w-4 h-4 overflow-hidden shrink-0 hidden"
                      alt=""
                      src="/icon--jamicons--outline--logos--circle12.svg"
                    />
                    <div className="flex flex-row py-0 px-1 items-center justify-center">
                      <div className="relative leading-[140%]">+2,5%</div>
                    </div>
                    <img
                      className="relative w-4 h-4 overflow-hidden shrink-0"
                      alt=""
                      src="/icon--iconoir--statup6.svg"
                    />
                  </div>
                  <img
                    className="relative w-6 h-6 overflow-hidden shrink-0 hidden"
                    alt=""
                    src="/icon--jamicons--outline--logos--pencil5.svg"
                  />
                </div>
              </div>
              <img
                className="self-stretch max-w-full overflow-hidden h-[220px] shrink-0 object-cover hidden"
                alt=""
                src="/picture3@2x.png"
              />
              <div className="self-stretch relative leading-[160%] hidden">
                Vehicula sit sit pharetra bibendum ut risus accumsan. Purus, in
                metus, enim, ipsum morbi euismod pellentesque. Mattis pharetra
                accumsan eget est mi enim, id. Sit quam tortor eu tellus non, in
                euismod integer.
              </div>
              <div className="self-stretch hidden flex-row pt-4 px-0 pb-0 items-center justify-start gap-[16px]">
                <img
                  className="rounded-81xl w-16 h-16 object-cover"
                  alt=""
                  src="/user-thumb2@2x.png"
                />
                <div className="flex-1 flex flex-col items-start justify-start">
                  <div className="self-stretch relative leading-[160%]">
                    Ralph Edwards
                  </div>
                  <div className="self-stretch relative text-base leading-[140%] text-blue-gray-600">{`Harvesting 32KWh `}</div>
                </div>
              </div>
            </div>
            <div className="self-stretch hidden flex-row p-4 items-start justify-start gap-[16px] z-[2] text-base text-white">
              <div className="flex-1 rounded-lg bg-blue-600 flex flex-row p-3 items-center justify-center border-[2px] border-solid border-blue-600">
                <img
                  className="relative w-6 h-6 overflow-hidden shrink-0 hidden"
                  alt=""
                  src="/icon--jamicons--outline--logos--plus15.svg"
                />
                <div className="flex flex-row py-0 px-4 items-center justify-center">
                  <div className="relative tracking-[0.5px] leading-[24px] font-medium">
                    Button Text
                  </div>
                </div>
                <img
                  className="relative w-6 h-6 overflow-hidden shrink-0"
                  alt=""
                  src="/icon--jamicons--outline--logos--arrowright23.svg"
                />
              </div>
              <div className="flex-1 rounded-lg flex flex-row p-3 items-center justify-center text-blue-600 border-[2px] border-solid border-blue-600">
                <img
                  className="relative w-6 h-6 overflow-hidden shrink-0 hidden"
                  alt=""
                  src="/icon--jamicons--outline--logos--plus16.svg"
                />
                <div className="flex flex-row py-0 px-4 items-center justify-center">
                  <div className="relative tracking-[0.5px] leading-[24px] font-medium">
                    Button Text
                  </div>
                </div>
                <img
                  className="relative w-6 h-6 overflow-hidden shrink-0"
                  alt=""
                  src="/icon--jamicons--outline--logos--arrowright24.svg"
                />
              </div>
            </div>
            <div className="my-0 mx-[!important] absolute top-[16px] left-[16px] rounded bg-purple-100 h-6 hidden flex-row py-2 px-1 box-border items-center justify-center z-[3] text-sm text-purple-800">
              <img
                className="relative w-4 h-4 overflow-hidden shrink-0 hidden"
                alt=""
                src="/icon--jamicons--outline--logos--circle21.svg"
              />
              <div className="flex flex-row py-0 px-1 items-center justify-center">
                <div className="relative leading-[140%]">Featured</div>
              </div>
              <img
                className="relative w-4 h-4 overflow-hidden shrink-0 hidden"
                alt=""
                src="/icon--iconoir--cancel11.svg"
              />
            </div>
            <img
              className="my-0 mx-[!important] absolute top-[172px] left-[calc(50%_-_48.2px)] rounded-81xl w-24 h-24 object-cover hidden z-[4]"
              alt=""
              src="/user-thumb3@2x.png"
            />
          </div>
          <div className="flex-1 rounded-8xs h-[354px] overflow-hidden flex flex-col items-start justify-start relative bg-[url(/public/content-box12@3x.png)] bg-cover bg-no-repeat bg-[top] sm:flex-[unset] sm:self-stretch">
            <img
              className="self-stretch max-w-full overflow-hidden max-h-full object-cover hidden z-[0]"
              alt=""
              src="/picture4@2x.png"
            />
            <div className="self-stretch [background:linear-gradient(180deg,_rgba(15,_23,_42,_0.5),_rgba(15,_23,_42,_0))] flex flex-col pt-6 px-6 pb-16 items-start justify-start gap-[16px] z-[1]">
              <div className="hidden flex-row items-center justify-center">
                <img
                  className="relative w-16 h-16 overflow-hidden shrink-0 hidden"
                  alt=""
                  src="/icon--iconoir--threestars7.svg"
                />
              </div>
              <div className="self-stretch flex flex-col items-start justify-start gap-[4px] text-base text-white">
                <div className="self-stretch relative leading-[110%] font-medium hidden">
                  Электрическая техника
                </div>
                <div className="self-stretch flex flex-row items-center justify-start gap-[8px] text-5xl">
                  <b className="flex-1 relative leading-[110%]">Аксессуары</b>
                  <div className="rounded bg-green-100 h-6 hidden flex-row py-2 px-1 box-border items-center justify-center text-sm text-green-800">
                    <img
                      className="relative w-4 h-4 overflow-hidden shrink-0 hidden"
                      alt=""
                      src="/icon--jamicons--outline--logos--circle14.svg"
                    />
                    <div className="flex flex-row py-0 px-1 items-center justify-center">
                      <div className="relative leading-[140%]">+2,5%</div>
                    </div>
                    <img
                      className="relative w-4 h-4 overflow-hidden shrink-0"
                      alt=""
                      src="/icon--iconoir--statup7.svg"
                    />
                  </div>
                  <img
                    className="relative w-6 h-6 overflow-hidden shrink-0 hidden"
                    alt=""
                    src="/icon--jamicons--outline--logos--pencil21.svg"
                  />
                </div>
              </div>
              <img
                className="self-stretch max-w-full overflow-hidden h-[220px] shrink-0 object-cover hidden"
                alt=""
                src="/picture5@2x.png"
              />
              <div className="self-stretch relative leading-[160%] hidden">
                Vehicula sit sit pharetra bibendum ut risus accumsan. Purus, in
                metus, enim, ipsum morbi euismod pellentesque. Mattis pharetra
                accumsan eget est mi enim, id. Sit quam tortor eu tellus non, in
                euismod integer.
              </div>
              <div className="self-stretch hidden flex-row pt-4 px-0 pb-0 items-center justify-start gap-[16px]">
                <img
                  className="rounded-81xl w-16 h-16 object-cover"
                  alt=""
                  src="/user-thumb4@2x.png"
                />
                <div className="flex-1 flex flex-col items-start justify-start">
                  <div className="self-stretch relative leading-[160%]">
                    Ralph Edwards
                  </div>
                  <div className="self-stretch relative text-base leading-[140%] text-blue-gray-600">{`Harvesting 32KWh `}</div>
                </div>
              </div>
            </div>
            <div className="self-stretch hidden flex-row p-4 items-start justify-start gap-[16px] z-[2] text-base text-white">
              <div className="flex-1 rounded-lg bg-blue-600 flex flex-row p-3 items-center justify-center border-[2px] border-solid border-blue-600">
                <img
                  className="relative w-6 h-6 overflow-hidden shrink-0 hidden"
                  alt=""
                  src="/icon--jamicons--outline--logos--plus17.svg"
                />
                <div className="flex flex-row py-0 px-4 items-center justify-center">
                  <div className="relative tracking-[0.5px] leading-[24px] font-medium">
                    Button Text
                  </div>
                </div>
                <img
                  className="relative w-6 h-6 overflow-hidden shrink-0"
                  alt=""
                  src="/icon--jamicons--outline--logos--arrowright17.svg"
                />
              </div>
              <div className="flex-1 rounded-lg flex flex-row p-3 items-center justify-center text-blue-600 border-[2px] border-solid border-blue-600">
                <img
                  className="relative w-6 h-6 overflow-hidden shrink-0 hidden"
                  alt=""
                  src="/icon--jamicons--outline--logos--plus18.svg"
                />
                <div className="flex flex-row py-0 px-4 items-center justify-center">
                  <div className="relative tracking-[0.5px] leading-[24px] font-medium">
                    Button Text
                  </div>
                </div>
                <img
                  className="relative w-6 h-6 overflow-hidden shrink-0"
                  alt=""
                  src="/icon--jamicons--outline--logos--arrowright18.svg"
                />
              </div>
            </div>
            <div className="my-0 mx-[!important] absolute top-[16px] left-[16px] rounded bg-purple-100 h-6 hidden flex-row py-2 px-1 box-border items-center justify-center z-[3] text-sm text-purple-800">
              <img
                className="relative w-4 h-4 overflow-hidden shrink-0 hidden"
                alt=""
                src="/icon--jamicons--outline--logos--circle22.svg"
              />
              <div className="flex flex-row py-0 px-1 items-center justify-center">
                <div className="relative leading-[140%]">Featured</div>
              </div>
              <img
                className="relative w-4 h-4 overflow-hidden shrink-0 hidden"
                alt=""
                src="/icon--iconoir--cancel12.svg"
              />
            </div>
            <img
              className="my-0 mx-[!important] absolute top-[172px] left-[calc(50%_-_48.2px)] rounded-81xl w-24 h-24 object-cover hidden z-[4]"
              alt=""
              src="/user-thumb5@2x.png"
            />
          </div>
          <div className="flex-1 rounded-8xs h-[354px] overflow-hidden flex flex-col items-start justify-start relative bg-[url(/public/content-box13@3x.png)] bg-cover bg-no-repeat bg-[top] sm:flex-[unset] sm:self-stretch">
            <img
              className="self-stretch max-w-full overflow-hidden max-h-full object-cover hidden z-[0]"
              alt=""
              src="/picture6@2x.png"
            />
            <div className="self-stretch [background:linear-gradient(180deg,_rgba(15,_23,_42,_0.5),_rgba(15,_23,_42,_0))] flex flex-col pt-6 px-6 pb-16 items-start justify-start gap-[16px] z-[1]">
              <div className="hidden flex-row items-center justify-center">
                <img
                  className="relative w-16 h-16 overflow-hidden shrink-0 hidden"
                  alt=""
                  src="/icon--iconoir--threestars8.svg"
                />
              </div>
              <div className="self-stretch flex flex-col items-start justify-start gap-[4px] text-base text-white">
                <div className="self-stretch relative leading-[110%] font-medium hidden">
                  Электрическая техника
                </div>
                <div className="self-stretch flex flex-row items-center justify-start gap-[8px] text-5xl">
                  <b className="flex-1 relative leading-[110%]">
                    Строительные материалы
                  </b>
                  <div className="rounded bg-green-100 h-6 hidden flex-row py-2 px-1 box-border items-center justify-center text-sm text-green-800">
                    <img
                      className="relative w-4 h-4 overflow-hidden shrink-0 hidden"
                      alt=""
                      src="/icon--jamicons--outline--logos--circle16.svg"
                    />
                    <div className="flex flex-row py-0 px-1 items-center justify-center">
                      <div className="relative leading-[140%]">+2,5%</div>
                    </div>
                    <img
                      className="relative w-4 h-4 overflow-hidden shrink-0"
                      alt=""
                      src="/icon--iconoir--statup8.svg"
                    />
                  </div>
                  <img
                    className="relative w-6 h-6 overflow-hidden shrink-0 hidden"
                    alt=""
                    src="/icon--jamicons--outline--logos--pencil3.svg"
                  />
                </div>
              </div>
              <img
                className="self-stretch max-w-full overflow-hidden h-[220px] shrink-0 object-cover hidden"
                alt=""
                src="/picture7@2x.png"
              />
              <div className="self-stretch relative leading-[160%] hidden">
                Vehicula sit sit pharetra bibendum ut risus accumsan. Purus, in
                metus, enim, ipsum morbi euismod pellentesque. Mattis pharetra
                accumsan eget est mi enim, id. Sit quam tortor eu tellus non, in
                euismod integer.
              </div>
              <div className="self-stretch hidden flex-row pt-4 px-0 pb-0 items-center justify-start gap-[16px]">
                <img
                  className="rounded-81xl w-16 h-16 object-cover"
                  alt=""
                  src="/user-thumb6@2x.png"
                />
                <div className="flex-1 flex flex-col items-start justify-start">
                  <div className="self-stretch relative leading-[160%]">
                    Ralph Edwards
                  </div>
                  <div className="self-stretch relative text-base leading-[140%] text-blue-gray-600">{`Harvesting 32KWh `}</div>
                </div>
              </div>
            </div>
            <div className="self-stretch hidden flex-row p-4 items-start justify-start gap-[16px] z-[2] text-base text-white">
              <div className="flex-1 rounded-lg bg-blue-600 flex flex-row p-3 items-center justify-center border-[2px] border-solid border-blue-600">
                <img
                  className="relative w-6 h-6 overflow-hidden shrink-0 hidden"
                  alt=""
                  src="/icon--jamicons--outline--logos--plus19.svg"
                />
                <div className="flex flex-row py-0 px-4 items-center justify-center">
                  <div className="relative tracking-[0.5px] leading-[24px] font-medium">
                    Button Text
                  </div>
                </div>
                <img
                  className="relative w-6 h-6 overflow-hidden shrink-0"
                  alt=""
                  src="/icon--jamicons--outline--logos--arrowright25.svg"
                />
              </div>
              <div className="flex-1 rounded-lg flex flex-row p-3 items-center justify-center text-blue-600 border-[2px] border-solid border-blue-600">
                <img
                  className="relative w-6 h-6 overflow-hidden shrink-0 hidden"
                  alt=""
                  src="/icon--jamicons--outline--logos--plus20.svg"
                />
                <div className="flex flex-row py-0 px-4 items-center justify-center">
                  <div className="relative tracking-[0.5px] leading-[24px] font-medium">
                    Button Text
                  </div>
                </div>
                <img
                  className="relative w-6 h-6 overflow-hidden shrink-0"
                  alt=""
                  src="/icon--jamicons--outline--logos--arrowright26.svg"
                />
              </div>
            </div>
            <div className="my-0 mx-[!important] absolute top-[16px] left-[16px] rounded bg-purple-100 h-6 hidden flex-row py-2 px-1 box-border items-center justify-center z-[3] text-sm text-purple-800">
              <img
                className="relative w-4 h-4 overflow-hidden shrink-0 hidden"
                alt=""
                src="/icon--jamicons--outline--logos--circle23.svg"
              />
              <div className="flex flex-row py-0 px-1 items-center justify-center">
                <div className="relative leading-[140%]">Featured</div>
              </div>
              <img
                className="relative w-4 h-4 overflow-hidden shrink-0 hidden"
                alt=""
                src="/icon--iconoir--cancel13.svg"
              />
            </div>
            <img
              className="my-0 mx-[!important] absolute top-[172px] left-[calc(50%_-_48.2px)] rounded-81xl w-24 h-24 object-cover hidden z-[4]"
              alt=""
              src="/user-thumb7@2x.png"
            />
          </div>
          <div className="flex-1 rounded-8xs h-[354px] overflow-hidden flex flex-col items-start justify-start relative bg-[url(/public/content-box14@3x.png)] bg-cover bg-no-repeat bg-[top] sm:flex-[unset] sm:self-stretch">
            <img
              className="self-stretch max-w-full overflow-hidden max-h-full object-cover hidden z-[0]"
              alt=""
              src="/picture8@2x.png"
            />
            <div className="self-stretch [background:linear-gradient(180deg,_rgba(15,_23,_42,_0.5),_rgba(15,_23,_42,_0))] flex flex-col pt-6 px-6 pb-16 items-start justify-start gap-[16px] z-[1]">
              <div className="hidden flex-row items-center justify-center">
                <img
                  className="relative w-16 h-16 overflow-hidden shrink-0 hidden"
                  alt=""
                  src="/icon--iconoir--threestars9.svg"
                />
              </div>
              <div className="self-stretch flex flex-col items-start justify-start gap-[4px] text-base text-white">
                <div className="self-stretch relative leading-[110%] font-medium hidden">
                  Электрическая техника
                </div>
                <div className="self-stretch flex flex-row items-center justify-start gap-[8px] text-5xl">
                  <b className="flex-1 relative leading-[110%]">
                    Бытовая техника
                  </b>
                  <div className="rounded bg-green-100 h-6 hidden flex-row py-2 px-1 box-border items-center justify-center text-sm text-green-800">
                    <img
                      className="relative w-4 h-4 overflow-hidden shrink-0 hidden"
                      alt=""
                      src="/icon--jamicons--outline--logos--circle181.svg"
                    />
                    <div className="flex flex-row py-0 px-1 items-center justify-center">
                      <div className="relative leading-[140%]">+2,5%</div>
                    </div>
                    <img
                      className="relative w-4 h-4 overflow-hidden shrink-0"
                      alt=""
                      src="/icon--iconoir--statup91.svg"
                    />
                  </div>
                  <img
                    className="relative w-6 h-6 overflow-hidden shrink-0 hidden"
                    alt=""
                    src="/icon--jamicons--outline--logos--pencil41.svg"
                  />
                </div>
              </div>
              <img
                className="self-stretch max-w-full overflow-hidden h-[220px] shrink-0 object-cover hidden"
                alt=""
                src="/picture9@2x.png"
              />
              <div className="self-stretch relative leading-[160%] hidden">
                Vehicula sit sit pharetra bibendum ut risus accumsan. Purus, in
                metus, enim, ipsum morbi euismod pellentesque. Mattis pharetra
                accumsan eget est mi enim, id. Sit quam tortor eu tellus non, in
                euismod integer.
              </div>
              <div className="self-stretch hidden flex-row pt-4 px-0 pb-0 items-center justify-start gap-[16px]">
                <img
                  className="rounded-81xl w-16 h-16 object-cover"
                  alt=""
                  src="/user-thumb8@2x.png"
                />
                <div className="flex-1 flex flex-col items-start justify-start">
                  <div className="self-stretch relative leading-[160%]">
                    Ralph Edwards
                  </div>
                  <div className="self-stretch relative text-base leading-[140%] text-blue-gray-600">{`Harvesting 32KWh `}</div>
                </div>
              </div>
            </div>
            <div className="self-stretch hidden flex-row p-4 items-start justify-start gap-[16px] z-[2] text-base text-white">
              <div className="flex-1 rounded-lg bg-blue-600 flex flex-row p-3 items-center justify-center border-[2px] border-solid border-blue-600">
                <img
                  className="relative w-6 h-6 overflow-hidden shrink-0 hidden"
                  alt=""
                  src="/icon--jamicons--outline--logos--plus21.svg"
                />
                <div className="flex flex-row py-0 px-4 items-center justify-center">
                  <div className="relative tracking-[0.5px] leading-[24px] font-medium">
                    Button Text
                  </div>
                </div>
                <img
                  className="relative w-6 h-6 overflow-hidden shrink-0"
                  alt=""
                  src="/icon--jamicons--outline--logos--arrowright27.svg"
                />
              </div>
              <div className="flex-1 rounded-lg flex flex-row p-3 items-center justify-center text-blue-600 border-[2px] border-solid border-blue-600">
                <img
                  className="relative w-6 h-6 overflow-hidden shrink-0 hidden"
                  alt=""
                  src="/icon--jamicons--outline--logos--plus22.svg"
                />
                <div className="flex flex-row py-0 px-4 items-center justify-center">
                  <div className="relative tracking-[0.5px] leading-[24px] font-medium">
                    Button Text
                  </div>
                </div>
                <img
                  className="relative w-6 h-6 overflow-hidden shrink-0"
                  alt=""
                  src="/icon--jamicons--outline--logos--arrowright28.svg"
                />
              </div>
            </div>
            <div className="my-0 mx-[!important] absolute top-[16px] left-[16px] rounded bg-purple-100 h-6 hidden flex-row py-2 px-1 box-border items-center justify-center z-[3] text-sm text-purple-800">
              <img
                className="relative w-4 h-4 overflow-hidden shrink-0 hidden"
                alt=""
                src="/icon--jamicons--outline--logos--circle24.svg"
              />
              <div className="flex flex-row py-0 px-1 items-center justify-center">
                <div className="relative leading-[140%]">Featured</div>
              </div>
              <img
                className="relative w-4 h-4 overflow-hidden shrink-0 hidden"
                alt=""
                src="/icon--iconoir--cancel14.svg"
              />
            </div>
            <img
              className="my-0 mx-[!important] absolute top-[172px] left-[calc(50%_-_48.2px)] rounded-81xl w-24 h-24 object-cover hidden z-[4]"
              alt=""
              src="/user-thumb9@2x.png"
            />
          </div>
        </div>
      </div>
      <div className="self-stretch flex flex-row py-8 px-24 items-center justify-start text-justify text-5xl text-black">
        <p className="m-0 flex-1 relative leading-[150%] font-light">
          Вы также можете обратиться к нашему опытному менеджеру, чтобы уточнить
          все детали относительно наших товаров. Наша команда готова ответить на
          любые вопросы, предоставить дополнительную информацию и помочь вам с
          выбором товаров, соответствующих вашим требованиям и потребностям.
          Наши менеджеры обладают глубокими знаниями о нашем ассортименте и
          всегда готовы обеспечить вам профессиональную поддержку. Не
          стесняйтесь связаться с нами через наши контактные данные, указанные
          на сайте, и мы с удовольствием поможем вам с любыми вопросами,
          возникающими при выборе и заказе товаров “Сфера”.
        </p>
      </div>
      <FComponent
        vk="/uilvk1.svg"
        youtube="/uilyoutube1.svg"
        logo="/logo-13@2x.png"
      />
    </div>
  );
};

export default Goods;
